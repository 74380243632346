var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import ConvoModal from "./ConvoModal";
import { UserIcon } from "@twilio-paste/icons/cjs/UserIcon";
import { Avatar } from "@twilio-paste/avatar";
import TimeAgo from "javascript-time-ago";
function getMessageTime(dateMessage, currentLocal) {
    var dateCreated = new Date(dateMessage);
    return dateCreated
        ? new TimeAgo(currentLocal).format(dateCreated, "twitter-now")
        : "";
}
var ImagePreviewModal = function (_a) {
    var image = _a.image, isOpen = _a.isOpen, handleClose = _a.handleClose, author = _a.author, date = _a.date, onDownload = _a.onDownload;
    var t = useTranslation(['common']).t;
    var _b = __read(React.useState("en-CA"), 2), timeLocal = _b[0], setTimeLocal = _b[1];
    React.useEffect(function () {
        var _a;
        // @ts-ignore
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        setTimeLocal("".concat(langCode, "-CA"));
    }, []);
    return (_jsx(ConvoModal, { title: t("Preview"), isModalOpen: isOpen, handleClose: handleClose, modalBody: _jsx("div", __assign({ className: "modal-body" }, { children: _jsxs("div", __assign({ className: "d-flex justify-content-between align-items-center" }, { children: [_jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [_jsx("div", __assign({ style: { width: 60 } }, { children: _jsx(Avatar, { size: "sizeIcon70", name: "avatar example", icon: UserIcon }) })), _jsxs("div", __assign({ className: "ml-2" }, { children: [_jsx("p", __assign({ className: "mb-0 font-weight-bold" }, { children: author })), _jsxs("p", __assign({ className: "text-muted mb-0" }, { children: [t("Sent"), " ", getMessageTime(date, timeLocal)] }))] }))] })), _jsx("button", __assign({ className: "btn btn-secondary", onClick: onDownload }, { children: t("Download") }))] })) })), modalFooter: _jsx("div", __assign({ className: "modal-footer", style: { borderTop: "none", maxWidth: "100%" } }, { children: _jsx("img", { className: "img-fluid", src: (window.URL || window.webkitURL).createObjectURL(image) }) })) }));
};
export default ImagePreviewModal;
