import { ActionType } from "../action-types";
var reducer = function (state, action) {
    if (state === void 0) { state = true; }
    switch (action.type) {
        case ActionType.UPDATE_LOADING_STATE:
            return action.payload;
        default:
            return state;
    }
};
export default reducer;
