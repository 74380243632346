var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ModalInputField from "./ModalInputField";
import AddParticipantFooter from "./addParticipantFooter";
import { ActionName } from "../../types";
import ConvoModal from "./ConvoModal";
var AddChatParticipantModal = function (props) {
    return (_jsx(_Fragment, { children: _jsx(ConvoModal, { handleClose: function () { return props.handleClose(); }, isModalOpen: props.isModalOpen, title: props.title, modalBody: _jsxs("div", __assign({ className: "modal-body" }, { children: [_jsx("h3", { children: "Add Chat participant" }), _jsx("form", { children: _jsx(ModalInputField, { label: "User identity", isFocused: true, input: props.name, placeholder: "exampleusername", onChange: props.setName, error: props.error, 
                            // error_text="Enter a valid user identity."
                            help_text: "The identity used by the participant in Conversations." }) })] })), modalFooter: _jsx(AddParticipantFooter, { isSaveDisabled: !props.name.trim() || !!props.error, actionName: ActionName.Save, onBack: function () {
                    props.onBack();
                }, action: props.action }) }) }));
};
export default AddChatParticipantModal;
