var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ConvoModal from "./ConvoModal";
// @ts-ignore
import LetteredAvatar from "react-lettered-avatar";
var ManageParticipantsModal = function (props) {
    return (_jsx(_Fragment, { children: _jsx(ConvoModal, { handleClose: function () { return props.handleClose(); }, isModalOpen: props.isModalOpen, title: props.title, modalBody: _jsx("table", __assign({ className: "table" }, { children: _jsx("tbody", { children: props.participantsList.length ? (props.participantsList.map(function (user) {
                        var _a;
                        return (_jsxs("tr", { children: [_jsx("td", { children: _jsx("div", __assign({ className: "pe-2" }, { children: _jsx(LetteredAvatar, { backgroundColor: "#dae6f0", size: 30, name: "".concat(user.identity) }) })) }), _jsx("td", { children: user.type == "chat"
                                        ? user.identity
                                        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            (_a = user.attributes["friendlyName"]) !== null && _a !== void 0 ? _a : "unknown" }), _jsx("td", __assign({ className: "text-right" }, { children: user.identity !== localStorage.getItem("email") ? (_jsx("img", { onClick: function () { return props.onParticipantRemove(user); }, src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/trash-blue.svg"), alt: "close", className: "close modal-close" })) : null }))] }, user.sid));
                    })) : (_jsx("tr", { children: _jsx("td", __assign({ colSpan: 3, className: "text-center" }, { children: _jsx("div", __assign({ className: "d-flex flex-column align-items-center" }, { children: _jsx("p", __assign({ className: "mt-2 mb-0" }, { children: "No participants" })) })) })) })) }) })) }) }));
};
export default ManageParticipantsModal;
