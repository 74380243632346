var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
var MessageActions = function (_a) {
    var messageText = _a.messageText, onMessageDelete = _a.onMessageDelete;
    var t = useTranslation(['common']).t;
    var _b = __read(React.useState(false), 2), showMenu = _b[0], setShowMenu = _b[1];
    var _c = __read(React.useState(false), 2), isToolTipOpen = _c[0], setIsToolTipOpen = _c[1];
    var toggleMenu = function () {
        setShowMenu(!showMenu);
    };
    return (_jsxs("div", __assign({ className: "position-relative", style: { paddingLeft: 6 } }, { children: [_jsx("img", { onClick: function () { return setIsToolTipOpen(!isToolTipOpen); }, src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/vertical-dots.svg"), alt: "dots icon", style: { cursor: "pointer" } }), isToolTipOpen && (_jsx("div", __assign({ className: "message-tooltip" }, { children: _jsxs("div", __assign({ onClick: onMessageDelete }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/trash-blue.svg"), alt: "delete" }), _jsx("span", { children: t("Delete") })] })) })))] })));
};
export default MessageActions;
