var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
var MessageMedia = function (_a) {
    var onDownload = _a.onDownload, onOpen = _a.onOpen, images = _a.images, files = _a.files, sending = _a.sending, attachments = _a.attachments;
    var t = useTranslation(["chat"]).t;
    var _b = __read(useState(false), 2), isMediaLoaded = _b[0], setMediaLoaded = _b[1];
    useEffect(function () {
        onDownload().then(function () {
            setMediaLoaded(true);
        });
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: images.map(function (img) { return (_jsxs("div", __assign({ className: "d-flex align-items-center justify-content-center position-relative rounded", style: { minHeight: "200px", minWidth: "200px" }, onClick: function () { return isMediaLoaded && onOpen(img.sid, img); } }, { children: [_jsx("div", __assign({ className: "position-absolute", style: { zIndex: 7, cursor: "pointer" } }, { children: sending || !isMediaLoaded ? (_jsx("div", __assign({ className: "spinner-border text-light", role: "status" }, { children: _jsx("div", { className: "loader-topbar" }) }))) : null })), _jsx("img", { className: "mw-100", style: { maxHeight: "300px", zIndex: 0, maxWidth: "400px" }, src: isMediaLoaded
                                ? (window.URL || window.webkitURL).createObjectURL(attachments[img.sid])
                                : undefined })] }), img.sid)); }) }), files.map(function (file, index) {
                var _a, _b;
                return (_jsxs("div", __assign({ className: "d-flex align-items-center p-3 mt-2 file-container", style: {
                        minWidth: "150px",
                        borderColor: "#CACDD8",
                        borderWidth: "1px",
                        borderStyle: "solid",
                    }, onClick: function () { return isMediaLoaded && onOpen(file.sid, undefined, file); } }, { children: [_jsx("div", __assign({ className: "me-3 align-items-start" }, { children: !isMediaLoaded || sending ? (_jsx("div", { className: "loader-topbar" })) : (_jsx("img", { style: { maxHeight: "51px" }, src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/pdf.svg"), alt: "document" })) })), _jsxs("div", __assign({ className: "w-100", style: { maxWidth: "calc(100% - 42px)" } }, { children: [_jsxs("p", __assign({ className: "font-weight-medium mb-0 text-truncate message-file-name", title: (_a = file.filename) !== null && _a !== void 0 ? _a : "" }, { children: [(_b = file.filename) !== null && _b !== void 0 ? _b : "", " (", " ", Math.round((file.size / Math.pow(2, 20)) * 100) / 100, " MB )"] })), sending || !isMediaLoaded ? (_jsx("p", __assign({ className: "text-muted mb-0" }, { children: !sending || !isMediaLoaded
                                        ? t("Downloading")
                                        : t("Uploading...") }))) : (_jsx("p", { className: "text-muted mb-0" }))] }))] }), String(file.filename) + index));
            })] }));
};
export default MessageMedia;
