var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo, useRef } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "./tabs/Tabs";
import { Client, } from "@twilio/conversations";
import { actionCreators } from "../store";
import ConversationContainer from "./conversations/ConversationContainer";
import ConversationsContainer from "./conversations/ConversationsContainer";
import { getConversationParticipants, getToken } from "../api";
import useAppAlert from "../hooks/useAppAlerts";
import Notifications from "./Notifications";
import stylesheet from "../styles";
import { handlePromiseRejection } from "../helpers";
import { initFcmServiceWorker, subscribeFcmNotifications, showNotification, } from "../firebase-support";
function loadUnreadMessagesCount(convo, updateUnreadMessages) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var count, _b, e_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    count = 0;
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 6, , 7]);
                    return [4 /*yield*/, convo.getUnreadMessagesCount()];
                case 2:
                    if (!((_a = (_c.sent())) !== null && _a !== void 0)) return [3 /*break*/, 3];
                    _b = _a;
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, convo.getMessagesCount()];
                case 4:
                    _b = (_c.sent());
                    _c.label = 5;
                case 5:
                    count = _b;
                    return [3 /*break*/, 7];
                case 6:
                    e_1 = _c.sent();
                    console.error("getUnreadMessagesCount threw an error", e_1);
                    return [3 /*break*/, 7];
                case 7:
                    updateUnreadMessages(convo.sid, count);
                    return [2 /*return*/];
            }
        });
    });
}
function handleParticipantsUpdate(participant, updateParticipants) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getConversationParticipants(participant.conversation)];
                case 1:
                    result = _a.sent();
                    updateParticipants(result, participant.conversation.sid);
                    return [2 /*return*/];
            }
        });
    });
}
function getSubscribedConversations(client) {
    return __awaiter(this, void 0, void 0, function () {
        var subscribedConversations, conversations;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, client.getSubscribedConversations()];
                case 1:
                    subscribedConversations = _a.sent();
                    conversations = subscribedConversations.items;
                    _a.label = 2;
                case 2:
                    if (!subscribedConversations.hasNextPage) return [3 /*break*/, 4];
                    return [4 /*yield*/, subscribedConversations.nextPage()];
                case 3:
                    subscribedConversations = _a.sent();
                    conversations = __spreadArray(__spreadArray([], __read(conversations), false), __read(subscribedConversations.items), false);
                    return [3 /*break*/, 2];
                case 4: return [2 /*return*/, conversations];
            }
        });
    });
}
var Main = function () {
    /* eslint-disable */
    var _a = __read(useState(), 2), connectionState = _a[0], setConnectionState = _a[1];
    var _b = __read(useState(), 2), client = _b[0], setClient = _b[1];
    var _c = __read(useState(false), 2), isCreatingConversation = _c[0], setIsCreatingConversation = _c[1];
    var _d = __read(useState(false), 2), isParticipantsComboboxVisible = _d[0], setParticipantsCombobox = _d[1];
    var _e = __read(useState(0), 2), clientIteration = _e[0], setClientIteration = _e[1];
    var token = useSelector(function (state) { return state.token; });
    var conversations = useSelector(function (state) { return state.convos; });
    var sid = useSelector(function (state) { return state.sid; });
    var sidRef = useRef("");
    var _f = __read(useAppAlert(), 2), alertsExist = _f[0], AlertsView = _f[1];
    sidRef.current = sid;
    var _g = __read(useState(""), 2), searchQuery = _g[0], setSearchQuery = _g[1];
    var _h = __read(useState(null), 2), companyConversation = _h[0], setCompanyConversation = _h[1];
    var handleSearchInputChange = function (event) {
        setSearchQuery(event.target.value);
    };
    var dispatch = useDispatch();
    var _j = bindActionCreators(actionCreators, dispatch), upsertMessages = _j.upsertMessages, updateLoadingState = _j.updateLoadingState, updateParticipants = _j.updateParticipants, updateUnreadMessages = _j.updateUnreadMessages, startTyping = _j.startTyping, endTyping = _j.endTyping, upsertConversation = _j.upsertConversation, login = _j.login, removeMessages = _j.removeMessages, removeConversation = _j.removeConversation, updateCurrentConversation = _j.updateCurrentConversation, addNotifications = _j.addNotifications, clearAttachments = _j.clearAttachments, setLastReadIndex = _j.setLastReadIndex;
    var updateTypingIndicator = function (participant, sid, callback) {
        var friendlyName = participant.attributes.friendlyName, identity = participant.identity;
        if (identity === localStorage.getItem("email")) {
            return;
        }
        callback(sid, identity || friendlyName || "");
    };
    useEffect(function () {
        initFcmServiceWorker().catch(function () {
            console.error("FCM initialization failed: no push notifications will be available");
        });
        var params = new URLSearchParams(window.location.search);
        var conversation_sid = params.get("conversation_sid");
        setCompanyConversation(conversation_sid);
    }, []);
    useEffect(function () {
        if (companyConversation && client) {
            client
                .getConversationBySid(companyConversation)
                .then(function (conversation) {
                // Assumes that updateCurrentConversation updates the 'sid' in the state.
                // If the logic is different, adjust it accordingly.
                updateCurrentConversation(conversation.sid);
            })
                .catch(function (error) {
                console.error("Failed to load conversation with sid ".concat(companyConversation), error);
            });
        }
    }, [companyConversation, client]);
    useEffect(function () {
        var client = new Client(token);
        setClient(client);
        var fcmInit = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, subscribeFcmNotifications(client)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        fcmInit().catch(function () {
            console.error("FCM initialization failed: no push notifications will be available");
        });
        client.on("conversationJoined", function (conversation) {
            upsertConversation(conversation);
            conversation.on("typingStarted", function (participant) {
                handlePromiseRejection(function () {
                    return updateTypingIndicator(participant, conversation.sid, startTyping);
                }, addNotifications);
            });
            conversation.on("typingEnded", function (participant) {
                handlePromiseRejection(function () { return updateTypingIndicator(participant, conversation.sid, endTyping); }, addNotifications);
            });
            handlePromiseRejection(function () { return __awaiter(void 0, void 0, void 0, function () {
                var result, messages;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(conversation.status === "joined")) return [3 /*break*/, 3];
                            return [4 /*yield*/, getConversationParticipants(conversation)];
                        case 1:
                            result = _a.sent();
                            updateParticipants(result, conversation.sid);
                            return [4 /*yield*/, conversation.getMessages()];
                        case 2:
                            messages = _a.sent();
                            upsertMessages(conversation.sid, messages.items);
                            loadUnreadMessagesCount(conversation, updateUnreadMessages);
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); }, addNotifications);
        });
        client.on("conversationRemoved", function (conversation) {
            updateCurrentConversation("");
            handlePromiseRejection(function () {
                removeConversation(conversation.sid);
                updateParticipants([], conversation.sid);
            }, addNotifications);
        });
        client.on("messageAdded", function (message) {
            upsertMessage(message, upsertMessages, updateUnreadMessages);
            if (message.author === localStorage.getItem("email")) {
                clearAttachments(message.conversation.sid, "-1");
            }
        });
        client.on("participantLeft", function (participant) {
            handlePromiseRejection(function () { return handleParticipantsUpdate(participant, updateParticipants); }, addNotifications);
        });
        client.on("participantUpdated", function (event) {
            handlePromiseRejection(function () { return handleParticipantsUpdate(event.participant, updateParticipants); }, addNotifications);
        });
        client.on("participantJoined", function (participant) {
            handlePromiseRejection(function () { return handleParticipantsUpdate(participant, updateParticipants); }, addNotifications);
        });
        client.on("conversationUpdated", function (_a) {
            var conversation = _a.conversation;
            handlePromiseRejection(function () { return upsertConversation(conversation); }, addNotifications);
        });
        client.on("messageUpdated", function (_a) {
            var message = _a.message;
            handlePromiseRejection(function () { return upsertMessage(message, upsertMessages, updateUnreadMessages); }, addNotifications);
        });
        client.on("messageRemoved", function (message) {
            handlePromiseRejection(function () { return removeMessages(message.conversation.sid, [message]); }, addNotifications);
        });
        client.on("pushNotification", function (event) {
            // @ts-ignore
            if (event.type != "twilio.conversations.new_message") {
                return;
            }
            if (Notification.permission === "granted") {
                showNotification(event);
            }
            else {
                console.log("Push notification is skipped", Notification.permission);
            }
        });
        client.on("tokenAboutToExpire", function () {
            getToken().then(function (token) {
                client.updateToken(token);
                login(token);
            });
        });
        client.on("tokenExpired", function () {
            getToken().then(function (token) {
                login(token);
                setClientIteration(function (x) { return x + 1; });
            });
        });
        client.on("connectionStateChanged", function (state) {
            setConnectionState(state);
        });
        updateLoadingState(false);
        getSubscribedConversations(client);
        return function () {
            client === null || client === void 0 ? void 0 : client.removeAllListeners();
        };
    }, [clientIteration]);
    function upsertMessage(message, upsertMessages, updateUnreadMessages) {
        //transform the message and add it to redux
        handlePromiseRejection(function () {
            if (sidRef.current === message.conversation.sid) {
                message.conversation.advanceLastReadMessageIndex(message.index);
            }
            upsertMessages(message.conversation.sid, [message]);
            loadUnreadMessagesCount(message.conversation, updateUnreadMessages);
        }, addNotifications);
    }
    var openedConversation = useMemo(function () { return conversations.find(function (convo) { return convo.sid === sid; }); }, [sid, conversations]);
    var handleSetIsCreatingConversation = function (status) {
        updateCurrentConversation("");
        setIsCreatingConversation(true);
    };
    return (_jsxs("div", __assign({ className: "container-fluid", style: stylesheet.appWrapper }, { children: [_jsx(AlertsView, {}), _jsx(Notifications, {}), _jsxs("div", __assign({ className: "row", style: stylesheet.appContainer(alertsExist) }, { children: [_jsxs("div", __assign({ className: "col-md-4 conversations-list-container" }, { children: [_jsx(Tabs, {}), _jsx(ConversationsContainer, { client: client, isParticipantsComboboxVisible: isParticipantsComboboxVisible, setParticipantsCombobox: setParticipantsCombobox, isCreatingConversation: isCreatingConversation, setIsCreatingConversation: handleSetIsCreatingConversation, 
                                /* @ts-ignore */
                                searchQuery: searchQuery, onSearchInputChange: handleSearchInputChange })] })), _jsx("div", __assign({ className: "messages-box col-md-8 ".concat(openedConversation && "opened-convo") }, { children: _jsx(ConversationContainer, { conversation: openedConversation, client: client, isParticipantsComboboxVisible: isParticipantsComboboxVisible, setParticipantsCombobox: setParticipantsCombobox, isCreatingConversation: isCreatingConversation, setIsCreatingConversation: handleSetIsCreatingConversation }) }))] }))] })));
};
export default Main;
