var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { CONNECTION_ERROR_MESSAGE } from "../constants";
var useAppAlert = function () {
    var _a = __read(useState(!window.navigator.onLine), 2), alertVisible = _a[0], setAlertVisible = _a[1];
    useEffect(function () {
        setAlertVisible(!window.navigator.onLine);
    }, [window.navigator.onLine]);
    var AlertComponent = function () { return (_jsx("div", __assign({ hidden: !alertVisible }, { children: _jsx("div", __assign({ className: "alert-message", "data-color": "yellow", "data-status": "active" }, { children: _jsx("div", __assign({ className: "container" }, { children: _jsxs("div", __assign({ className: "content" }, { children: [_jsxs("div", __assign({ className: "c-center" }, { children: [_jsx("img", { src: "/static/vendors/images/icons/warning-y.svg", alt: "" }), _jsxs("p", { children: [CONNECTION_ERROR_MESSAGE, " "] })] })), _jsx("div", __assign({ className: "close", "data-modal": "alert-msg-close" }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/cross.svg"), alt: "close" }) }))] })) })) })) }))); };
    return [alertVisible, AlertComponent];
};
export default useAppAlert;
