var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Failed = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "#606B85" : _b, _c = _a.style, style = _c === void 0 ? {} : _c;
    return (_jsx("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: style }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.24723 0.51662L15.4834 6.75277C16.1722 7.4416 16.1722 8.5584 15.4834 9.24723L9.24723 15.4834C8.5584 16.1722 7.4416 16.1722 6.75277 15.4834L0.51662 9.24723C-0.172207 8.5584 -0.172207 7.4416 0.51662 6.75277L6.75277 0.51662C7.4416 -0.172207 8.5584 -0.172207 9.24723 0.51662ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM7.98975 5H8.01011C8.62102 5.0074 9.0826 5.59236 8.98757 6.24396L8.55993 8.48168C8.5192 8.77786 8.28163 9 8.00332 9H7.99653C7.71823 9 7.48066 8.77786 7.43993 8.48168L7.01229 6.24396C6.91726 5.59236 7.38562 5 7.98975 5Z", fill: color }) })));
};
export default Failed;
