var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { store } from "../components/chat/store";
import App from "../components/chat/components/App";
import { useMediaQuery } from "react-responsive";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18n from "../i18n/i18n";
import { PageLoading } from "../components/react-components/shared-components/Loading/PageLoading";
var ChatApp = function () {
    var isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
    useEffect(function () {
        var _a;
        // @ts-ignore
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        if ((langCode === null || langCode === void 0 ? void 0 : langCode.length) === 2) {
            i18next.changeLanguage(langCode);
        }
    }, []);
    return (_jsx(Suspense, __assign({ fallback: _jsx(PageLoading, {}) }, { children: _jsx(I18nextProvider, __assign({ i18n: i18n }, { children: _jsx("div", __assign({ className: "h-100 chat-provider" }, { children: _jsx(React.StrictMode, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(App, {}) })) }) })) })) })));
};
// # https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
var root = document.getElementById("chat");
if (root) {
    render(_jsx(ChatApp, {}), root);
}
