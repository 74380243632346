var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import MessageFile from "./MessageFile";
function useWindowSize() {
    var _a = __read(useState(0), 2), size = _a[0], setSize = _a[1];
    useLayoutEffect(function () {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return function () { return window.removeEventListener("resize", updateSize); };
    }, []);
    return size;
}
var MessageInput = function (props) {
    var t = useTranslation(["chat"]).t;
    var _a = __read(useState(0), 2), cursorPosition = _a[0], setCursorPostions = _a[1];
    var width = useWindowSize();
    //500 is the width of the rest of the components. So totalWidth-500=widthOfInput
    return (_jsxs("div", __assign({ className: "message-input-container" }, { children: [_jsx("textarea", { className: "message-input message-textarea hide-scroll-bar " +
                    (props.assets.length ? "has-assets" : ""), onChange: function (e) {
                    setCursorPostions(e.currentTarget.selectionStart);
                    props.onChange(e.currentTarget.value);
                }, 
                /* @ts-ignore */
                onKeyPress: props.onKeyPress, "aria-describedby": "message_help_text", 
                /* @ts-ignore */
                placeholder: t("Write something..."), id: "message-input", name: "message-input", value: props.message, autoFocus: true, onFocus: function (e) {
                    return e.currentTarget.setSelectionRange(cursorPosition, cursorPosition);
                } }), props.assets.length ? (_jsx("div", __assign({ className: "assets-container d-flex flex-direction-row" }, { children: props.assets.map(function (_a) {
                    var name = _a.name, size = _a.size;
                    return (_jsx(MessageFile, { media: { filename: name, size: size }, onRemove: function () { return props.onFileRemove(name + "_" + size); } }, "".concat(name + "_" + size)));
                }) }))) : null] })));
};
export default MessageInput;
