var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { MessageStatus, } from "./store/reducers/messageListReducer";
import { CONVERSATION_PAGE_SIZE, UNEXPECTED_ERROR_MESSAGE, } from "./constants";
import { unexpectedErrorNotification } from "./helpers";
import { getSdkMessageObject } from "./conversations-objects";
export function addConversation(name, updateParticipants, client, addNotifications) {
    return __awaiter(this, void 0, void 0, function () {
        var conversation, participants, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(name.length > 0 && client !== undefined)) return [3 /*break*/, 6];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, client.createConversation({
                            friendlyName: name,
                        })];
                case 2:
                    conversation = _a.sent();
                    return [4 /*yield*/, conversation.join()];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, getConversationParticipants(conversation)];
                case 4:
                    participants = _a.sent();
                    updateParticipants(participants, conversation.sid);
                    // successNotification({
                    //   message: CONVERSATION_MESSAGES.CREATED,
                    //   addNotifications,
                    // });
                    return [2 /*return*/, conversation];
                case 5:
                    e_1 = _a.sent();
                    unexpectedErrorNotification(addNotifications);
                    return [2 /*return*/, Promise.reject(UNEXPECTED_ERROR_MESSAGE)];
                case 6: return [2 /*return*/, Promise.reject(UNEXPECTED_ERROR_MESSAGE)];
            }
        });
    });
}
export function addParticipant(name, proxyName, chatParticipant, convo, addNotifications) {
    return __awaiter(this, void 0, void 0, function () {
        var result, e_2, result, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (convo === undefined) {
                        return [2 /*return*/, Promise.reject(UNEXPECTED_ERROR_MESSAGE)];
                    }
                    if (!(chatParticipant && name.length > 0)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, convo.add(name)];
                case 2:
                    result = _a.sent();
                    // successNotification({
                    //   message: i18n.t(PARTICIPANT_MESSAGES.ADDED),
                    //   addNotifications,
                    // });
                    return [2 /*return*/, result];
                case 3:
                    e_2 = _a.sent();
                    return [2 /*return*/, Promise.reject(e_2)];
                case 4:
                    if (!(!chatParticipant && name.length > 0 && proxyName.length > 0)) return [3 /*break*/, 8];
                    _a.label = 5;
                case 5:
                    _a.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, convo.addNonChatParticipant(proxyName, name, {
                            friendlyName: name,
                        })];
                case 6:
                    result = _a.sent();
                    // successNotification({
                    //   message: i18n.t(PARTICIPANT_MESSAGES.ADDED),
                    //   addNotifications,
                    // });
                    return [2 /*return*/, result];
                case 7:
                    e_3 = _a.sent();
                    unexpectedErrorNotification(addNotifications);
                    return [2 /*return*/, Promise.reject(e_3)];
                case 8: return [2 /*return*/, Promise.reject(UNEXPECTED_ERROR_MESSAGE)];
            }
        });
    });
}
export function getToken() {
    var _a, _b, _c, _d, _e, _f, _g;
    return __awaiter(this, void 0, void 0, function () {
        var requestAddress, response, error_1;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    requestAddress = process.env
                        .REACT_APP_ACCESS_TOKEN_SERVICE_URL;
                    if (!requestAddress) {
                        return [2 /*return*/, Promise.reject("REACT_APP_ACCESS_TOKEN_SERVICE_URL is not configured, cannot login")];
                    }
                    _h.label = 1;
                case 1:
                    _h.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.get(requestAddress)];
                case 2:
                    response = _h.sent();
                    if (((_a = response.data) === null || _a === void 0 ? void 0 : _a.token) === "") {
                        return [2 /*return*/, "Something went wrong."];
                    }
                    localStorage.setItem("full_name", (_b = response.data) === null || _b === void 0 ? void 0 : _b.full_name);
                    localStorage.setItem("email", (_c = response.data) === null || _c === void 0 ? void 0 : _c.email);
                    return [2 /*return*/, (_d = response.data) === null || _d === void 0 ? void 0 : _d.token];
                case 3:
                    error_1 = _h.sent();
                    if (axios.isAxiosError(error_1) && ((_e = error_1.response) === null || _e === void 0 ? void 0 : _e.status) === 401) {
                        return [2 /*return*/, Promise.reject((_f = error_1.response.data) !== null && _f !== void 0 ? _f : "Authentication error.")];
                    }
                    (_g = process.stderr) === null || _g === void 0 ? void 0 : _g.write("ERROR received from ".concat(requestAddress, ": ").concat(error_1, "\n"));
                    return [2 /*return*/, Promise.reject("ERROR received from ".concat(requestAddress, ": ").concat(error_1, "\n"))];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getMessageStatus(message, channelParticipants) {
    return __awaiter(this, void 0, void 0, function () {
        var statuses, sdkMessage, receipts;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    statuses = (_a = {},
                        _a[MessageStatus.Delivered] = 0,
                        _a[MessageStatus.Read] = 0,
                        _a[MessageStatus.Failed] = 0,
                        _a[MessageStatus.Sending] = 0,
                        _a);
                    if (message.index === -1) {
                        return [2 /*return*/, Promise.resolve(__assign(__assign({}, statuses), (_b = {}, _b[MessageStatus.Sending] = 1, _b)))];
                    }
                    channelParticipants.forEach(function (participant) {
                        if (participant.identity == localStorage.getItem("email") ||
                            participant.type !== "chat") {
                            return;
                        }
                        if (participant.lastReadMessageIndex &&
                            participant.lastReadMessageIndex >= message.index) {
                            statuses[MessageStatus.Read] += 1;
                        }
                        else if (participant.lastReadMessageIndex !== -1) {
                            // statuses[MessageStatus.Delivered] += 1; FIXME don't need Delivered status for chat particpants?
                        }
                    });
                    if (!message.aggregatedDeliveryReceipt) return [3 /*break*/, 2];
                    sdkMessage = getSdkMessageObject(message);
                    return [4 /*yield*/, sdkMessage.getDetailedDeliveryReceipts()];
                case 1:
                    receipts = _c.sent();
                    receipts.forEach(function (receipt) {
                        if (receipt.status === "read") {
                            statuses[MessageStatus.Read] += 1;
                        }
                        if (receipt.status === "delivered") {
                            statuses[MessageStatus.Delivered] += 1;
                        }
                        if (receipt.status === "failed" || receipt.status === "undelivered") {
                            statuses[MessageStatus.Failed] += 1;
                        }
                        if (receipt.status === "sent" || receipt.status === "queued") {
                            statuses[MessageStatus.Sending] += 1;
                        }
                    });
                    _c.label = 2;
                case 2: return [2 /*return*/, statuses];
            }
        });
    });
}
export var getConversationParticipants = function (conversation) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, conversation.getParticipants()];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); };
export var removeParticipant = function (conversation, participant, addNotifications) { return __awaiter(void 0, void 0, void 0, function () {
    var e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, conversation.removeParticipant(participant)];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                e_4 = _a.sent();
                unexpectedErrorNotification(addNotifications);
                return [2 /*return*/, Promise.reject(UNEXPECTED_ERROR_MESSAGE)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getBlobFile = function (media, addNotifications) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, getFileUrl(media)];
            case 1:
                url = _a.sent();
                return [4 /*yield*/, fetch(url)];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.blob()];
            case 3:
                e_5 = _a.sent();
                unexpectedErrorNotification(addNotifications);
                return [2 /*return*/, Promise.reject(UNEXPECTED_ERROR_MESSAGE)];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var getFileUrl = function (media) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, media.getContentTemporaryUrl().then()];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getMessages = function (conversation) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, conversation.getMessages(CONVERSATION_PAGE_SIZE)];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); };
