export var conversationsMap = new Map();
export var messagesMap = new Map();
export var mediaMap = new Map();
export var participantsMap = new Map();
var capitalize = function (string) {
    return "".concat(string[0].toUpperCase()).concat(string.substring(1));
};
var getSdkObject = function (objectMap, sid, type) {
    var sdkObject = objectMap.get(sid);
    if (!sdkObject) {
        throw new Error("".concat(capitalize(type), " with SID ").concat(sid, " was not found."));
    }
    return sdkObject;
};
export var getSdkConversationObject = function (reduxConversation) {
    return getSdkObject(conversationsMap, reduxConversation.sid, "conversation");
};
export var getSdkMessageObject = function (reduxMessage) {
    return getSdkObject(messagesMap, reduxMessage.sid, "message");
};
export var getSdkMediaObject = function (reduxMedia) {
    return getSdkObject(mediaMap, reduxMedia.sid, "media");
};
export var getSdkParticipantObject = function (reduxParticipany) {
    return getSdkObject(participantsMap, reduxParticipany.sid, "participant");
};
