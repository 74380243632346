var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import ConversationView from "./ConversationView";
import { actionCreators } from "../../store";
import { getTypingMessage, unexpectedErrorNotification } from "../../helpers";
import { UNEXPECTED_ERROR_MESSAGE } from "../../constants";
import { getSdkConversationObject } from "../../conversations-objects";
import { useTranslation } from "react-i18next";
function getLastMessage(messages, typingData, MediaMessageText, noMessage) {
    if (messages === undefined || messages === null) {
        return _jsx("div", { className: "loader-topbar" });
    }
    if (typingData.length) {
        return getTypingMessage(typingData);
    }
    if (messages.length === 0) {
        return noMessage;
    }
    return messages[messages.length - 1].body || MediaMessageText;
}
function isMyMessage(messages) {
    if (messages === undefined || messages === null || messages.length === 0) {
        return false;
    }
    return messages[messages.length - 1].author === localStorage.getItem("email")
        ? messages[messages.length - 1]
        : false;
}
function updateCurrentConvo(setSid, convo, updateParticipants) {
    return __awaiter(this, void 0, void 0, function () {
        var participants, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setSid(convo.sid);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getSdkConversationObject(convo).getParticipants()];
                case 2:
                    participants = _b.sent();
                    updateParticipants(participants, convo.sid);
                    return [3 /*break*/, 4];
                case 3:
                    _a = _b.sent();
                    return [2 /*return*/, Promise.reject(UNEXPECTED_ERROR_MESSAGE)];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function setUnreadMessagesCount(currentconvoSid, convoSid, unreadMessages, updateUnreadMessages) {
    if (currentconvoSid == convoSid && unreadMessages[convoSid] !== 0) {
        updateUnreadMessages(convoSid, 0);
        return 0;
    }
    if (currentconvoSid == convoSid) {
        return 0;
    }
    return unreadMessages[convoSid];
}
var ConversationsList = function (props) {
    var sid = useSelector(function (state) { return state.sid; });
    // const allConversations = useSelector((state: AppState) => state.convos);
    var messages = useSelector(function (state) { return state.messages; });
    var unreadMessages = useSelector(function (state) { return state.unreadMessages; });
    var participants = useSelector(function (state) { return state.participants; });
    var typingData = useSelector(function (state) { return state.typingData; });
    var t = useTranslation(["chat"]).t;
    var pageSize = 3;
    var _a = __read(useState(1), 2), pageIndex = _a[0], setPageIndex = _a[1];
    var selectPaginatedConversations = function (state, pageSize, pageIndex, searchQuery) {
        var filteredConversations = state.convos.filter(function (convo) {
            /* @ts-ignore */
            return convo.friendlyName.toLowerCase().includes(searchQuery.toLowerCase());
        });
        return filteredConversations.slice(0, pageIndex * pageSize);
    };
    var conversations = useSelector(function (state) {
        return selectPaginatedConversations(state, pageSize, pageIndex, props.searchQuery);
    });
    var selectTotalConversations = function (state) {
        return state.convos.length;
    };
    var totalConversations = useSelector(function (state) {
        return selectTotalConversations(state);
    });
    var handleLoadMore = function () {
        setPageIndex(function (prevPageIndex) { return prevPageIndex + 1; });
    };
    var hasMoreConversations = pageIndex * pageSize < totalConversations;
    var dispatch = useDispatch();
    var _b = bindActionCreators(actionCreators, dispatch), updateCurrentConversation = _b.updateCurrentConversation, updateParticipants = _b.updateParticipants, updateUnreadMessages = _b.updateUnreadMessages, setLastReadIndex = _b.setLastReadIndex, addNotifications = _b.addNotifications;
    if (conversations === undefined || conversations === null) {
        return _jsx("div", { className: "empty" });
    }
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ id: "conversation-list", className: "conversations-list card-list hide-scroll-bar " }, { children: [conversations.map(function (convo) {
                    var _a, _b, _c, _d;
                    return (_jsx(ConversationView, { convoId: convo.sid, setSid: updateCurrentConversation, currentConvoSid: sid, 
                        /* @ts-ignore */
                        lastMessage: (_b = getLastMessage(messages[convo.sid], (_a = typingData[convo.sid]) !== null && _a !== void 0 ? _a : [], t("Media messages"), t("No messages"))) !== null && _b !== void 0 ? _b : "", messages: messages[convo.sid], typingInfo: (_c = typingData[convo.sid]) !== null && _c !== void 0 ? _c : [], myMessage: isMyMessage(messages[convo.sid]), unreadMessagesCount: setUnreadMessagesCount(sid, convo.sid, unreadMessages, updateUnreadMessages), updateUnreadMessages: updateUnreadMessages, participants: (_d = participants[convo.sid]) !== null && _d !== void 0 ? _d : [], convo: convo, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var lastMessage, _a;
                            var _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _c.trys.push([0, 4, , 5]);
                                        setLastReadIndex((_b = convo.lastReadMessageIndex) !== null && _b !== void 0 ? _b : -1);
                                        return [4 /*yield*/, updateCurrentConvo(updateCurrentConversation, convo, updateParticipants)];
                                    case 1:
                                        _c.sent();
                                        //update unread messages
                                        updateUnreadMessages(convo.sid, 0);
                                        lastMessage = messages[convo.sid].length &&
                                            messages[convo.sid][messages[convo.sid].length - 1];
                                        if (!(lastMessage && lastMessage.index !== -1)) return [3 /*break*/, 3];
                                        return [4 /*yield*/, getSdkConversationObject(convo).advanceLastReadMessageIndex(lastMessage.index)];
                                    case 2:
                                        _c.sent();
                                        _c.label = 3;
                                    case 3: return [3 /*break*/, 5];
                                    case 4:
                                        _a = _c.sent();
                                        unexpectedErrorNotification(addNotifications);
                                        return [3 /*break*/, 5];
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); } }, convo.sid));
                }), hasMoreConversations && (_jsx("div", __assign({ className: "d-flex justify-content-center mt-4" }, { children: _jsx("button", __assign({ className: "btn btn-primary compact create-convo-button", onClick: handleLoadMore }, { children: t("Load more") })) })))] })) }));
};
export default ConversationsList;
