var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Main from "./Main";
import { actionCreators } from "../store";
import { getToken } from "../api";
function App() {
    var _a = __read(useState(true), 2), loading = _a[0], setLoading = _a[1];
    var dispatch = useDispatch();
    var login = bindActionCreators(actionCreators, dispatch).login;
    var token = useSelector(function (state) { return state.token; });
    useEffect(function () {
        getToken()
            .then(function (token) {
            login(token);
        })
            .catch(function () {
            // localStorage.setItem("email", "");
        })
            .finally(function () {
            setLoading(false);
        });
    }, []);
    if (loading) {
        return (_jsx("div", __assign({ className: "d-flex justify-content-center align-items-center position-absolute", style: { height: "100%", width: "100%" } }, { children: _jsx("div", { className: "loader-topbar" }) })));
    }
    return _jsx(Main, {});
}
export default App;
