var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, createRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import SettingsMenu from "./SettingsMenu";
import ManageParticipantsModal from "../modals/manageParticipantsModal";
import { Content } from "../../types";
import { addParticipant, removeParticipant } from "../../api";
import AddChatParticipantModal from "../modals/addChatMemberModal";
import AddSMSParticipantModal from "../modals/addSMSParticipantModal";
import AddWhatsAppParticipantModal from "../modals/addWhatsAppParticipant";
import { actionCreators } from "../../store";
import ActionErrorModal from "../modals/ActionErrorModal";
import { CONVERSATION_MESSAGES, ERROR_MODAL_MESSAGES, SMS_PREFIX, WHATSAPP_PREFIX, } from "../../constants";
import { successNotification, unexpectedErrorNotification, } from "../../helpers";
import { getSdkConversationObject, getSdkParticipantObject, } from "../../conversations-objects";
import { useTranslation } from "react-i18next";
var Settings = function (props) {
    var t = useTranslation(["chat"]).t;
    var _a = __read(useState(false), 2), isManageParticipantOpen = _a[0], setIsManageParticipantOpen = _a[1];
    var handleParticipantClose = function () { return setIsManageParticipantOpen(false); };
    var _b = __read(useState(false), 2), isAddChatOpen = _b[0], setIsAddChatOpen = _b[1];
    // TODO: move to app loading state
    var _c = __read(useState(false), 2), isLoading = _c[0], setLoading = _c[1];
    var handleChatOpen = function () { return setIsAddChatOpen(true); };
    var handleChatClose = function () { return setIsAddChatOpen(false); };
    var _d = __read(useState(false), 2), isAddSMSOpen = _d[0], setIsAddSMSOpen = _d[1];
    var handleSMSOpen = function () { return setIsAddSMSOpen(true); };
    var handleSMSClose = function () { return setIsAddSMSOpen(false); };
    var _e = __read(useState(false), 2), isAddWhatsAppOpen = _e[0], setIsAddWhatsAppOpen = _e[1];
    var handleWhatsAppOpen = function () { return setIsAddWhatsAppOpen(true); };
    var handleWhatsAppClose = function () { return setIsAddWhatsAppOpen(false); };
    var _f = __read(useState(""), 2), name = _f[0], setName = _f[1];
    var _g = __read(useState(""), 2), error = _g[0], setError = _g[1];
    var _h = __read(useState(""), 2), nameProxy = _h[0], setNameProxy = _h[1];
    var _j = __read(useState(""), 2), errorProxy = _j[0], setErrorProxy = _j[1];
    var _k = __read(useState(), 2), showError = _k[0], setErrorToShow = _k[1];
    var _l = __read(useState(), 2), errorData = _l[0], setErrorData = _l[1];
    var nameInputRef = createRef();
    var dispatch = useDispatch();
    var _m = bindActionCreators(actionCreators, dispatch), updateCurrentConversation = _m.updateCurrentConversation, updateConversation = _m.updateConversation, addNotifications = _m.addNotifications;
    var sdkConvo = useMemo(function () { return getSdkConversationObject(props.convo); }, [props.convo.sid]);
    function emptyData() {
        setName("");
        setNameProxy("");
        setError("");
        setErrorProxy("");
    }
    function setErrors(errorText) {
        setError(errorText);
        setErrorProxy(errorText);
    }
    return (_jsxs(_Fragment, { children: [_jsx(SettingsMenu, { onParticipantListOpen: function () { return setIsManageParticipantOpen(true); }, leaveConvo: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, sdkConvo.leave()];
                            case 1:
                                _b.sent();
                                successNotification({
                                    message: t(CONVERSATION_MESSAGES.LEFT),
                                    addNotifications: addNotifications,
                                });
                                updateCurrentConversation("");
                                return [3 /*break*/, 3];
                            case 2:
                                _a = _b.sent();
                                unexpectedErrorNotification(addNotifications);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, updateConvo: function (val) {
                    return sdkConvo
                        .updateFriendlyName(val)
                        .then(function (convo) {
                        updateConversation(convo.sid, convo);
                        successNotification({
                            message: t(CONVERSATION_MESSAGES.NAME_CHANGED),
                            addNotifications: addNotifications,
                        });
                    })
                        .catch(function (e) {
                        setErrorData(e);
                        setErrorToShow(ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME);
                    });
                }, conversation: props.convo, addNotifications: addNotifications }), _jsx(ActionErrorModal, { errorText: showError || ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME, isOpened: !!showError, onClose: function () {
                    setErrorToShow(false);
                    setErrorData(undefined);
                }, error: errorData }), isManageParticipantOpen && (_jsx(ManageParticipantsModal, { handleClose: handleParticipantClose, isModalOpen: isManageParticipantOpen, title: t("Manage Participants"), participantsCount: props.participants.length, participantsList: props.participants, onClick: function (content) {
                    handleParticipantClose();
                    switch (content) {
                        case Content.AddSMS:
                            handleSMSOpen();
                            return null;
                        case Content.AddWhatsApp:
                            handleWhatsAppOpen();
                            return null;
                        case Content.AddChat:
                            handleChatOpen();
                            return null;
                        default:
                            return null;
                    }
                }, onParticipantRemove: function (participant) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, removeParticipant(sdkConvo, getSdkParticipantObject(participant), addNotifications)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } })), isAddSMSOpen && (_jsx(AddSMSParticipantModal, { name: name, proxyName: nameProxy, isModalOpen: isAddSMSOpen, title: "Manage Participants", setName: function (name) {
                    setName(name);
                    setErrors("");
                }, setProxyName: function (name) {
                    setNameProxy(name);
                    setErrors("");
                }, error: error, errorProxy: errorProxy, nameInputRef: nameInputRef, handleClose: function () {
                    emptyData();
                    handleSMSClose();
                }, onBack: function () {
                    emptyData();
                    handleSMSClose();
                    setIsManageParticipantOpen(true);
                }, action: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, addParticipant(SMS_PREFIX + name, SMS_PREFIX + nameProxy, false, sdkConvo, addNotifications)];
                            case 1:
                                _a.sent();
                                emptyData();
                                handleSMSClose();
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                setErrorData(e_1);
                                setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); } })), isAddWhatsAppOpen && (_jsx(AddWhatsAppParticipantModal, { name: name, proxyName: nameProxy, isModalOpen: isAddWhatsAppOpen, title: "Manage Participants", setName: function (name) {
                    setName(name);
                    setErrors("");
                }, setProxyName: function (name) {
                    setNameProxy(name);
                    setErrors("");
                }, error: error, errorProxy: errorProxy, nameInputRef: nameInputRef, handleClose: function () {
                    emptyData();
                    handleWhatsAppClose();
                }, onBack: function () {
                    emptyData();
                    handleWhatsAppClose();
                    setIsManageParticipantOpen(true);
                }, action: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, addParticipant(WHATSAPP_PREFIX + name, WHATSAPP_PREFIX + nameProxy, false, sdkConvo, addNotifications)];
                            case 1:
                                _a.sent();
                                emptyData();
                                handleWhatsAppClose();
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                setErrorData(e_2);
                                setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); } })), isAddChatOpen && (_jsx(AddChatParticipantModal, { name: name, isModalOpen: isAddChatOpen, title: t("Manage Participants"), setName: function (name) {
                    setName(name);
                    setErrors("");
                }, error: error, nameInputRef: nameInputRef, handleClose: function () {
                    emptyData();
                    handleChatClose();
                }, onBack: function () {
                    emptyData();
                    handleChatClose();
                    setIsManageParticipantOpen(true);
                }, action: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var e_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, addParticipant(name, nameProxy, true, sdkConvo, addNotifications)];
                            case 1:
                                _a.sent();
                                emptyData();
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _a.sent();
                                setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
                                setErrorData(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); } })), isLoading ? (_jsx("div", __assign({ style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                } }, { children: "...loading" }))) : null] }));
};
export default Settings;
