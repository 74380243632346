var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Delivered = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "#606B85" : _b, _c = _a.style, style = _c === void 0 ? {} : _c;
    return (_jsx("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: style }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.7103 10.7047L11.7062 6.71128C11.8943 6.52328 12 6.26831 12 6.00244C12 5.73657 11.8943 5.4816 11.7062 5.29361C11.5181 5.10561 11.263 5 10.9969 5C10.7309 5 10.4758 5.10561 10.2877 5.29361L7.00103 8.58818L5.71234 7.29032C5.52423 7.10232 5.2691 6.99672 5.00307 6.99672C4.73703 6.99672 4.4819 7.10232 4.2938 7.29032C4.10568 7.47831 4 7.73328 4 7.99915C4 8.26502 4.10568 8.51999 4.2938 8.70799L6.29175 10.7047C6.38462 10.7983 6.4951 10.8725 6.61684 10.9232C6.73857 10.9739 6.86914 11 7.00103 11C7.1329 11 7.26347 10.9739 7.3852 10.9232C7.50694 10.8725 7.61742 10.7983 7.7103 10.7047Z", fill: color }) })));
};
export default Delivered;
