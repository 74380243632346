var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowBackIcon } from "@twilio-paste/icons/esm/ArrowBackIcon";
var AddParticipantFooter = function (props) {
    var _a;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "modal-footer" }, { children: [_jsx("div", __assign({ className: "d-flex justify-content-start" }, { children: _jsxs("button", __assign({ className: "btn btn-secondary", onClick: function () {
                            props.onBack();
                        } }, { children: [_jsx(ArrowBackIcon, { decorative: true, title: "Back to manage participants", size: "sizeIcon10" }), "Back"] })) })), _jsx("div", __assign({ className: "d-flex justify-content-end" }, { children: _jsx("button", __assign({ disabled: (_a = props.isSaveDisabled) !== null && _a !== void 0 ? _a : false, className: "btn btn-primary", onClick: function () {
                            props.action();
                        } }, { children: props.actionName })) }))] })) }));
};
export default AddParticipantFooter;
