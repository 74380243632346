var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionType } from "../action-types";
var initialState = {};
var reducer = function (state, action) {
    var _a, _b, _c, _d, _e;
    var _f, _g;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.ADD_ATTACHMENT: {
            var _h = action.payload, channelSid = _h.channelSid, messageSid = _h.messageSid, mediaSid = _h.mediaSid, attachment = _h.attachment;
            state[channelSid] = (_f = state[channelSid]) !== null && _f !== void 0 ? _f : {};
            state[channelSid][messageSid] = (_g = state[channelSid][messageSid]) !== null && _g !== void 0 ? _g : {};
            return __assign(__assign({}, state), (_a = {}, _a[channelSid] = __assign(__assign({}, (state[channelSid] || {})), (_b = {}, _b[messageSid] = Object.assign(state[channelSid][messageSid], (_c = {},
                _c[mediaSid] = attachment,
                _c)), _b)), _a));
        }
        case ActionType.CLEAR_ATTACHMENTS: {
            var _j = action.payload, channelSid = _j.channelSid, messageSid = _j.messageSid;
            return __assign(__assign({}, state), (_d = {}, _d[channelSid] = __assign(__assign({}, (state[channelSid] || {})), (_e = {}, _e[messageSid] = {}, _e)), _d));
        }
        default:
            return state;
    }
};
export default reducer;
