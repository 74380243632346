import { ActionType } from "../action-types";
var initialState = {};
var reducer = function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.UPDATE_UNREAD_MESSAGES:
            //get convo sid and messages to add from payload
            var _b = action.payload, channelSid = _b.channelSid, unreadCount = _b.unreadCount;
            //overwrite the channelSid unread count
            return Object.assign({}, state, (_a = {}, _a[channelSid] = unreadCount, _a));
        default:
            return state;
    }
};
export default reducer;
