import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-ignore
import LetteredAvatar from "react-lettered-avatar";
var ConversationAvatar = function (props) {
    var currentUser = localStorage.getItem("email");
    var participants = props.participants.length === 2
        ? (props.participants.find(function (item) { return item.identity !== currentUser; }) || {})
            .identity || "N/A"
        : props.participants.length < 2
            ? (props.participants[0] || {}).identity || "No participant found"
            : "".concat(props.participants.length, " +");
    return (_jsx(_Fragment, { children: _jsx(LetteredAvatar, { backgroundColor: "#dae6f0", size: 48, name: participants }) }));
};
export default ConversationAvatar;
