var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
var ConversationSearch = function (props) {
    var t = useTranslation(["chat"]).t;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "search-bar" }, { children: [_jsx("input", { onChange: function (e) { return props.handleSearchQuery(e.target.value); }, type: "text", value: props.searchQuery, 
                    /* @ts-ignore */
                    placeholder: t("Search") }), _jsx("button", __assign({ type: "button" }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/recherche.svg"), alt: "search" }) }))] })) }));
};
export default ConversationSearch;
