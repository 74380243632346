import { ActionType } from "../action-types";
export var login = function (token) {
    return function (dispatch) {
        dispatch({
            type: ActionType.LOGIN,
            payload: token,
        });
    };
};
export var logout = function () {
    return function (dispatch) {
        dispatch({
            type: ActionType.LOGOUT,
        });
    };
};
export var upsertConversation = function (convo) {
    return function (dispatch) {
        dispatch({
            type: ActionType.UPSERT_CONVERSATION,
            payload: convo,
        });
    };
};
export var removeConversation = function (sid) {
    return function (dispatch) {
        dispatch({
            type: ActionType.REMOVE_CONVERSATION,
            payload: sid,
        });
    };
};
export var updateCurrentConversation = function (sid) {
    return function (dispatch) {
        dispatch({
            type: ActionType.UPDATE_CURRENT_CONVERSATION,
            payload: sid,
        });
    };
};
export var setLastReadIndex = function (index) {
    return function (dispatch) {
        dispatch({
            type: ActionType.CONVERSATION_LAST_READ_INDEX,
            payload: index,
        });
    };
};
export var upsertMessages = function (channelSid, messages) {
    return function (dispatch) {
        dispatch({
            type: ActionType.ADD_MESSAGES,
            payload: { channelSid: channelSid, messages: messages },
        });
    };
};
export var pushMessages = function (channelSid, messages) {
    return function (dispatch) {
        dispatch({
            type: ActionType.PUSH_MESSAGES,
            payload: { channelSid: channelSid, messages: messages },
        });
    };
};
export var removeMessages = function (channelSid, messages) {
    return function (dispatch) {
        dispatch({
            type: ActionType.REMOVE_MESSAGES,
            payload: { channelSid: channelSid, messages: messages },
        });
    };
};
export var updateLoadingState = function (loadingStatus) {
    return function (dispatch) {
        dispatch({
            type: ActionType.UPDATE_LOADING_STATE,
            payload: loadingStatus,
        });
    };
};
export var updateParticipants = function (participants, sid) {
    return function (dispatch) {
        dispatch({
            type: ActionType.UPDATE_PARTICIPANTS,
            payload: { participants: participants, sid: sid },
        });
    };
};
export var updateUnreadMessages = function (channelSid, unreadCount) {
    return function (dispatch) {
        dispatch({
            type: ActionType.UPDATE_UNREAD_MESSAGES,
            payload: { channelSid: channelSid, unreadCount: unreadCount },
        });
    };
};
export var updateConversation = function (channelSid, parameters) {
    return function (dispatch) {
        dispatch({
            type: ActionType.UPDATE_CONVERSATION,
            payload: { channelSid: channelSid, parameters: parameters },
        });
    };
};
export var addAttachment = function (channelSid, messageSid, mediaSid, attachment) {
    return function (dispatch) {
        dispatch({
            type: ActionType.ADD_ATTACHMENT,
            payload: { channelSid: channelSid, messageSid: messageSid, mediaSid: mediaSid, attachment: attachment },
        });
    };
};
export var clearAttachments = function (channelSid, messageSid) {
    return function (dispatch) {
        dispatch({
            type: ActionType.CLEAR_ATTACHMENTS,
            payload: { channelSid: channelSid, messageSid: messageSid },
        });
    };
};
export var startTyping = function (channelSid, participant) {
    return function (dispatch) {
        dispatch({
            type: ActionType.TYPING_STARTED,
            payload: { channelSid: channelSid, participant: participant },
        });
    };
};
export var endTyping = function (channelSid, participant) {
    return function (dispatch) {
        dispatch({
            type: ActionType.TYPING_ENDED,
            payload: { channelSid: channelSid, participant: participant },
        });
    };
};
export var addNotifications = function (notifications) {
    return function (dispatch) {
        dispatch({
            type: ActionType.ADD_NOTIFICATIONS,
            payload: notifications,
        });
    };
};
export var removeNotifications = function (toIndex) {
    return function (dispatch) {
        dispatch({
            type: ActionType.REMOVE_NOTIFICATIONS,
            payload: toIndex,
        });
    };
};
