var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { DEFAULT_CONVERSATION_NAME } from "../../constants";
var ConversationTitle = function (props) {
    var currentUser = localStorage.getItem("email");
    var filteredParticipants = props.participants.filter(function (participant) { return participant.identity !== currentUser; });
    var t = useTranslation(["chat"]).t;
    var firstThreeParticipants = filteredParticipants.slice(0, 3);
    var identities = firstThreeParticipants
        .map(function (participant) { return participant.identity; })
        .join(", ");
    var Participants = function () { return (
    /**
     * Maybe find better way later
     * we keep this quick
     */
    _jsxs(_Fragment, { children: [filteredParticipants.length === 1 && (_jsx("p", __assign({ className: "first-participant" }, { children: identities }))), filteredParticipants.length === 2 && (_jsx("p", __assign({ className: "first-participant" }, { children: 
                /* @ts-ignore */
                firstThreeParticipants.find(function (participant) { return participant.identity !== currentUser; }).identity }))), filteredParticipants.length === 3 && (_jsx("p", __assign({ className: "first-participant" }, { children: identities }))), filteredParticipants.length > 3 && (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: "first-participant" }, { children: identities })), _jsxs("p", __assign({ className: "more-participants" }, { children: ["+ ", filteredParticipants.length - 3, " ", t("personnes")] }))] })), filteredParticipants.length === 0 && (_jsx("p", __assign({ className: "no-participant" }, { children: t("No participant") })))] })); };
    return (_jsx(_Fragment, { children: props.title !== DEFAULT_CONVERSATION_NAME ? (props.title) : (_jsx(Participants, {})) }));
};
export default ConversationTitle;
