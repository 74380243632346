var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useLayoutEffect, useMemo, useRef, useState, } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageList from "./MessageList";
import { getMessages } from "../../api";
import { CONVERSATION_PAGE_SIZE } from "../../constants";
import { getSdkConversationObject } from "../../conversations-objects";
import { useTranslation } from "react-i18next";
export function loadMessages(conversation, upsertMessage, currentMessages) {
    if (currentMessages === void 0) { currentMessages = []; }
    return __awaiter(this, void 0, void 0, function () {
        var convoSid, sidExists, paginator, messages;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    convoSid = conversation.sid;
                    sidExists = !!currentMessages.filter(function (_a) {
                        var sid = _a.sid;
                        return sid === convoSid;
                    })
                        .length;
                    if (!!sidExists) return [3 /*break*/, 2];
                    return [4 /*yield*/, getMessages(getSdkConversationObject(conversation))];
                case 1:
                    paginator = _a.sent();
                    messages = paginator.items;
                    //save to redux
                    upsertMessage(convoSid, messages);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
var MessagesBox = function (props) {
    var _a, _b;
    var messages = props.messages, convo = props.convo, loadingState = props.loadingState, lastReadIndex = props.lastReadIndex, upsertMessage = props.upsertMessage;
    var _c = __read(useState((messages === null || messages === void 0 ? void 0 : messages.length) === CONVERSATION_PAGE_SIZE), 2), hasMore = _c[0], setHasMore = _c[1];
    var t = useTranslation(["chat"]).t;
    var _d = __read(useState(false), 2), loading = _d[0], setLoading = _d[1];
    var _e = __read(useState(0), 2), height = _e[0], setHeight = _e[1];
    var _f = __read(useState(null), 2), paginator = _f[0], setPaginator = _f[1];
    var listRef = useRef(null);
    var sdkConvo = useMemo(function () { return getSdkConversationObject(convo); }, [convo.sid]);
    useEffect(function () {
        if (!messages && convo && !loadingState) {
            loadMessages(convo, upsertMessage);
        }
    }, []);
    useLayoutEffect(function () {
        var _a;
        var currentHeight = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight;
        if (currentHeight && currentHeight > height && loading) {
            // for preventing immediate downloading of the next messages page
            setTimeout(function () {
                setHeight(currentHeight !== null && currentHeight !== void 0 ? currentHeight : 0);
                setLoading(false);
            }, 3000);
        }
    }, [(_a = listRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight]);
    useEffect(function () {
        getMessages(sdkConvo).then(function (paginator) {
            setHasMore(paginator.hasPrevPage);
            setPaginator(paginator);
        });
    }, [convo]);
    useEffect(function () {
        if ((messages === null || messages === void 0 ? void 0 : messages.length) && messages[messages.length - 1].index !== -1) {
            sdkConvo.advanceLastReadMessageIndex(messages[messages.length - 1].index);
        }
    }, [messages, convo]);
    var lastConversationReadIndex = useMemo(function () {
        return (messages === null || messages === void 0 ? void 0 : messages.length) &&
            messages[messages.length - 1].author !== localStorage.getItem("email")
            ? lastReadIndex
            : -1;
    }, [lastReadIndex, messages]);
    var fetchMore = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, moreMessages;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!paginator) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, (paginator === null || paginator === void 0 ? void 0 : paginator.prevPage())];
                case 1:
                    result = _a.sent();
                    if (!result) {
                        return [2 /*return*/];
                    }
                    moreMessages = result.items;
                    setLoading(true);
                    setPaginator(result);
                    setHasMore(result.hasPrevPage);
                    upsertMessage(convo.sid, moreMessages);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", __assign({ id: "scrollable", className: "custom-scroll-bar d-flex flex-column-reverse w-100 overflow-scroll px-4 h-100" }, { children: _jsx(InfiniteScroll, __assign({ dataLength: (_b = messages === null || messages === void 0 ? void 0 : messages.length) !== null && _b !== void 0 ? _b : 0, next: fetchMore, hasMore: !loading && hasMore, loader: _jsx("div", __assign({ className: "text-center my-4" }, { children: _jsx("div", { className: "loader-topbar" }) })), scrollableTarget: "scrollable", className: "d-flex overflow-hidden flex-column-reverse pt-4", inverse: true, scrollThreshold: "20px" }, { children: _jsx("div", __assign({ ref: listRef, className: "messages-list hide-scroll-bar" }, { children: _jsx(MessageList, { messages: messages !== null && messages !== void 0 ? messages : [], conversation: convo, participants: props.participants, lastReadIndex: lastConversationReadIndex }) })) })) }), convo.sid));
};
export default MessagesBox;
