var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputType } from "../../types";
var PrefixType;
(function (PrefixType) {
    PrefixType["SMS"] = "SMS";
    PrefixType["WhatsApp"] = "WhatsApp";
})(PrefixType || (PrefixType = {}));
function getPrefixType(prefixType) {
    switch (prefixType) {
        case PrefixType.SMS:
            return "+";
        case PrefixType.WhatsApp:
            return "WhatsApp +";
        default:
            return undefined;
    }
}
var ModalInputField = function (props) {
    var _a, _b;
    var prefixType = getPrefixType(props.prefixType);
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: "modal-input", className: "form-label" }, { children: props.label })), _jsxs("div", __assign({ className: "input-group" }, { children: [prefixType && _jsx("span", __assign({ className: "input-group-text" }, { children: prefixType })), _jsx("input", { id: props.id, autoFocus: (_a = props.isFocused) !== null && _a !== void 0 ? _a : false, type: (_b = props.inputType) !== null && _b !== void 0 ? _b : InputType.Text, value: props.input, placeholder: props.placeholder, onChange: function (e) { return props.onChange(e.currentTarget.value); }, onBlur: props.onBlur }), props.showPassword !== undefined && (_jsx("div", __assign({ className: "input-group-append" }, { children: _jsx("button", __assign({ type: "button", className: "btn btn-link", onClick: function () {
                                if (props.setShowPassword !== undefined) {
                                    props.setShowPassword(!props.showPassword);
                                }
                            } }, { children: props.showPassword ? (_jsx("i", { className: "bi bi-eye-slash" })) : (_jsx("i", { className: "bi bi-eye" })) })) })))] })), props.error && (_jsx("div", __assign({ id: "error_help_text", className: "invalid-feedback" }, { children: props.error }))), !props.error && props.help_text && (_jsx("div", __assign({ id: "error_help_text", className: "form-text" }, { children: props.help_text })))] }));
};
export default ModalInputField;
