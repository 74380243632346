import { ActionType } from "../action-types";
var initialState = -1;
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.CONVERSATION_LAST_READ_INDEX:
            return action.payload;
        default:
            return state;
    }
};
export default reducer;
