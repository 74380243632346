var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Message } from "@twilio/conversations";
import { mediaMap, messagesMap } from "../../conversations-objects";
import { ActionType } from "../action-types";
export var MessageStatus;
(function (MessageStatus) {
    MessageStatus["Sending"] = "Sending";
    MessageStatus["Sent"] = "Sent";
    MessageStatus["Delivered"] = "Delivered";
    MessageStatus["Failed"] = "Failed";
    MessageStatus["None"] = "none (incoming)";
    MessageStatus["Read"] = "Read";
})(MessageStatus || (MessageStatus = {}));
var initialState = {};
var reduxifyMessage = function (message) {
    var _a, _b;
    return ({
        sid: message.sid,
        index: message.index,
        body: message.body,
        author: message.author,
        participantSid: message.participantSid,
        attributes: message.attributes,
        dateCreated: message.dateCreated,
        aggregatedDeliveryReceipt: message.aggregatedDeliveryReceipt
            ? {
                total: message.aggregatedDeliveryReceipt.total,
                sent: message.aggregatedDeliveryReceipt.sent,
                delivered: message.aggregatedDeliveryReceipt.delivered,
                read: message.aggregatedDeliveryReceipt.read,
                undelivered: message.aggregatedDeliveryReceipt.undelivered,
                failed: message.aggregatedDeliveryReceipt.failed,
            }
            : null,
        attachedMedia: (_b = (_a = message.attachedMedia) === null || _a === void 0 ? void 0 : _a.map(function (el) { return ({
            sid: el.sid,
            filename: el.filename,
            contentType: el.contentType,
            size: el.size,
            category: el.category,
        }); })) !== null && _b !== void 0 ? _b : null,
    });
};
var reducer = function (state, action) {
    var e_1, _a, _b, e_2, _c, _d, e_3, _e, _f;
    var _g, _h, _j;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.PUSH_MESSAGES: {
            var _k = action.payload, channelSid = _k.channelSid, messagesToAdd = _k.messages;
            var existingMessages = (_g = state[channelSid]) !== null && _g !== void 0 ? _g : [];
            try {
                for (var messagesToAdd_1 = __values(messagesToAdd), messagesToAdd_1_1 = messagesToAdd_1.next(); !messagesToAdd_1_1.done; messagesToAdd_1_1 = messagesToAdd_1.next()) {
                    var message = messagesToAdd_1_1.value;
                    messagesMap.set(message.sid, message);
                    if (message.attachedMedia) {
                        message.attachedMedia.forEach(function (media) {
                            mediaMap.set(media.sid, media);
                        });
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (messagesToAdd_1_1 && !messagesToAdd_1_1.done && (_a = messagesToAdd_1.return)) _a.call(messagesToAdd_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return Object.assign({}, state, (_b = {},
                _b[channelSid] = existingMessages.concat(messagesToAdd.map(reduxifyMessage)),
                _b));
        }
        case ActionType.ADD_MESSAGES: {
            //get convo sid and messages to add from payload
            var _l = action.payload, channelSid = _l.channelSid, messagesToAdd_3 = _l.messages;
            //get existing messages for the convo
            var existingMessages = (_h = state[channelSid]) !== null && _h !== void 0 ? _h : [];
            var filteredExistingMessages = existingMessages.filter(function (message) {
                return !messagesToAdd_3.find(function (value) {
                    return value.body === message.body &&
                        value.author === message.author &&
                        (message.index === -1 || value.index === message.index);
                });
            });
            //add new messages to exisiting, ignore duplicates
            var messagesUnique = __spreadArray(__spreadArray([], __read(filteredExistingMessages), false), __read(messagesToAdd_3.map(reduxifyMessage)), false);
            try {
                for (var messagesToAdd_2 = __values(messagesToAdd_3), messagesToAdd_2_1 = messagesToAdd_2.next(); !messagesToAdd_2_1.done; messagesToAdd_2_1 = messagesToAdd_2.next()) {
                    var message = messagesToAdd_2_1.value;
                    if (message instanceof Message) {
                        messagesMap.set(message.sid, message);
                        if (message.attachedMedia) {
                            message.attachedMedia.forEach(function (media) {
                                mediaMap.set(media.sid, media);
                            });
                        }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (messagesToAdd_2_1 && !messagesToAdd_2_1.done && (_c = messagesToAdd_2.return)) _c.call(messagesToAdd_2);
                }
                finally { if (e_2) throw e_2.error; }
            }
            var sortedMessages = messagesUnique.sort(function (a, b) {
                return a.index - b.index;
            });
            //overwrite the channelSid messages
            return Object.assign({}, state, (_d = {},
                _d[channelSid] = sortedMessages,
                _d));
        }
        case ActionType.REMOVE_MESSAGES: {
            var _m = action.payload, channelSid = _m.channelSid, messagesToRemove_2 = _m.messages;
            var existingMessages = (_j = state[channelSid]) !== null && _j !== void 0 ? _j : [];
            var messages = existingMessages.filter(function (_a) {
                var index = _a.index;
                return !messagesToRemove_2.find(function (_a) {
                    var messageIndex = _a.index;
                    return messageIndex === index;
                });
            });
            try {
                for (var messagesToRemove_1 = __values(messagesToRemove_2), messagesToRemove_1_1 = messagesToRemove_1.next(); !messagesToRemove_1_1.done; messagesToRemove_1_1 = messagesToRemove_1.next()) {
                    var message = messagesToRemove_1_1.value;
                    messagesMap.delete(message.sid);
                    if (message.attachedMedia) {
                        message.attachedMedia.forEach(function (media) {
                            mediaMap.delete(media.sid);
                        });
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (messagesToRemove_1_1 && !messagesToRemove_1_1.done && (_e = messagesToRemove_1.return)) _e.call(messagesToRemove_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
            return Object.assign({}, state, (_f = {},
                _f[channelSid] = messages,
                _f));
        }
        default:
            return state;
    }
};
export default reducer;
