var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useMenuState } from "@twilio-paste/menu";
var Reactions;
(function (Reactions) {
    Reactions["HEART"] = "heart";
})(Reactions || (Reactions = {}));
var reactionsExist = function (reactions) { var _a; return reactions && ((_a = reactions[Reactions.HEART]) === null || _a === void 0 ? void 0 : _a.length); };
export var ReactionsBox = function (_a) {
    var _b, _c, _d;
    var _e = _a.reactions, reactions = _e === void 0 ? {} : _e, onReactionsChanged = _a.onReactionsChanged;
    var menu = useMenuState({
        placement: "top-start",
    });
    var user = (_b = localStorage.getItem("email")) !== null && _b !== void 0 ? _b : "";
    var onUpdateReaction = function (reaction) {
        var _a;
        var _b, _c;
        var reactionUsers = (_b = reactions === null || reactions === void 0 ? void 0 : reactions[reaction]) !== null && _b !== void 0 ? _b : [];
        onReactionsChanged(__assign(__assign({}, reactions), (_a = {}, _a[reaction] = ((_c = reactions === null || reactions === void 0 ? void 0 : reactions[reaction]) === null || _c === void 0 ? void 0 : _c.includes(user))
            ? reactionUsers.filter(function (participant) { return participant != user; })
            : __spreadArray(__spreadArray([], __read(reactionUsers), false), [user], false), _a)));
    };
    var allReactionsByCurrentUser = function () {
        return Object.keys(reactions)
            .filter(function (reaction) { var _a; return (_a = reactions[reaction]) === null || _a === void 0 ? void 0 : _a.length; })
            .every(function (reaction) { var _a; return (_a = reactions[reaction]) === null || _a === void 0 ? void 0 : _a.includes(user); });
    };
    var ReactionItem = function (_a) {
        var _b;
        var emoji = _a.emoji, reactionId = _a.reactionId, count = _a.count;
        return (_jsxs("div", __assign({ className: "reaction-item" +
                (((_b = reactions === null || reactions === void 0 ? void 0 : reactions[reactionId]) === null || _b === void 0 ? void 0 : _b.includes(user)) ? " user-reaction" : "") +
                (count ? " with-count" : ""), onClick: function () {
                menu.hide();
                onUpdateReaction(reactionId);
            } }, { children: [emoji, " ", _jsxs("span", __assign({ className: "reaction-count" }, { children: [" ", count] }))] })));
    };
    var isReactionsByCurrentUser = allReactionsByCurrentUser();
    return (_jsx("div", __assign({ className: "reactions-box" }, { children: reactionsExist(reactions) ? (_jsx("div", __assign({ className: "reactions-container" +
                (isReactionsByCurrentUser ? " reactions-by-current-user" : "") }, { children: ((_c = reactions === null || reactions === void 0 ? void 0 : reactions[Reactions.HEART]) === null || _c === void 0 ? void 0 : _c.length) ? (_jsx(ReactionItem, { emoji: _jsx("img", { className: "message-like-icon", src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/").concat(isReactionsByCurrentUser ? "like-filled" : "heart-b", ".svg"), alt: "delivered" }), reactionId: Reactions.HEART, count: (_d = reactions === null || reactions === void 0 ? void 0 : reactions[Reactions.HEART]) === null || _d === void 0 ? void 0 : _d.length })) : (_jsx(ReactionItem, { emoji: _jsx("img", { className: "message-like-icon", src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/heart-b.svg"), alt: "delivered" }), reactionId: Reactions.HEART })) }))) : (_jsx(ReactionItem, { emoji: _jsx("img", { className: "message-like-icon", src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/heart-b.svg"), alt: "delivered" }), reactionId: Reactions.HEART })) })));
};
export default ReactionsBox;
