var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createRef } from "react";
var ConvoModal = function (props) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var nameInputRef = createRef();
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "modal ".concat(props.isModalOpen ? "show" : ""), tabIndex: -1, role: "dialog", style: { display: props.isModalOpen ? "block" : "none" } }, { children: _jsx("div", __assign({ className: "modal-dialog modal-fullscreen-md-down modal-dialog-centered", role: "document" }, { children: _jsxs("div", __assign({ className: "modal-content" }, { children: [_jsxs("div", __assign({ className: "modal-header" }, { children: [_jsx("h5", __assign({ className: "modal-title" }, { children: props.title })), _jsx("img", { onClick: props.handleClose, src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/close.svg"), alt: "close", className: "close modal-close" })] })), _jsx("div", __assign({ className: "modal-body" }, { children: props.modalBody })), props.modalFooter && (_jsx("div", __assign({ className: "modal-footer" }, { children: props.modalFooter })))] })) })) })) }));
};
export default ConvoModal;
