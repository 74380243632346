var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ActionType } from "../action-types";
var reducer = function (state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case ActionType.ADD_NOTIFICATIONS:
            return __spreadArray(__spreadArray([], __read(state), false), __read(action.payload), false);
        case ActionType.REMOVE_NOTIFICATIONS: {
            var removeCount = action.payload;
            if (removeCount + 1 > state.length) {
                return [];
            }
            return state.slice(removeCount, state.length);
        }
        default:
            return state;
    }
};
export default reducer;
