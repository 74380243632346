var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { participantsMap } from "../../conversations-objects";
import { ActionType } from "../action-types";
var initialState = {};
var reduxifyParticipant = function (participant) { return ({
    sid: participant.sid,
    attributes: participant.attributes,
    identity: participant.identity,
    type: participant.type,
    lastReadMessageIndex: participant.lastReadMessageIndex,
}); };
var reducer = function (state, action) {
    var e_1, _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.UPDATE_PARTICIPANTS:
            var _c = action.payload, participants = _c.participants, sid = _c.sid;
            try {
                for (var participants_1 = __values(participants), participants_1_1 = participants_1.next(); !participants_1_1.done; participants_1_1 = participants_1.next()) {
                    var participant = participants_1_1.value;
                    participantsMap.set(participant.sid, participant);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (participants_1_1 && !participants_1_1.done && (_a = participants_1.return)) _a.call(participants_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return Object.assign({}, state, (_b = {},
                _b[sid] = participants.map(reduxifyParticipant),
                _b));
        default:
            return state;
    }
};
export default reducer;
