export default {
    appContainer: function (alertsExist) { return ({
        display: "flex",
        flexDirection: "row",
        height: alertsExist ? "calc(100% - 122px)" : "calc(100% - 72px)",
        width: "100%",
        pointerEvents: alertsExist ? "none" : "auto",
    }); },
    flex: {
        display: "flex",
    },
    appWrapper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
    },
    convosWrapper: {
        height: "100%",
        width: 320,
        position: "relative",
        backgroundColor: "#fffff",
    },
    messagesWrapper: {
        flex: 1,
    },
    appHeader: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#06033A",
        padding: "20px 25px",
    },
    appLogoTitle: {
        fontSize: "16px",
        fontWeight: 500,
        textAlign: "center",
        color: "#FFFFFF",
        lineHeight: "32px",
        paddingLeft: "20px",
    },
    userTile: {
        display: "flex",
        lineHeight: "32px",
        color: "#fff",
    },
    paginationSpinner: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 16,
    },
    app: {
        flex: 1,
        height: "100%",
        width: "100%",
        //position: "absolute",
    },
    buttonWrapper: {
        flexDirection: "row",
        paddingRight: "16px",
    },
    convosWrapperBox: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    convoList: {
        overflowX: "scroll",
        overflowY: "hidden",
    },
    collapseButtonBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
        paddingTop: "12px",
        paddingBottom: "20px",
        paddingRight: "16px",
        paddingLeft: "16px",
        position: "absolute",
        bottom: 0,
        borderTop: "1px solid #CACDD8",
    },
    newConvoButton: {
        padding: "14px 0px",
        width: "100%",
        borderBottom: "1px solid #CACDD8",
    },
    loginContainer: {
        height: "100%",
        backgroundColor: "#001489",
    },
    logo: {
        width: "42px",
    },
    loginTitle: {
        paddingTop: "2px",
        color: "#FFFFFF",
        fontFamily: "Inter",
        fontSize: "20px",
        lineHeight: "28px",
        fontWeight: 500,
    },
    subTitle: {
        color: "#AEB2C1",
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "20px",
        paddingBottom: "40px",
    },
    loginContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 3,
    },
    loginForm: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
    },
    userInput: {
        paddingTop: "24px",
        width: "320px",
        marginLeft: "24px",
        marginRight: "24px",
    },
    passwordInput: {
        paddingTop: "16px",
        width: "320px",
        marginLeft: "24px",
        marginRight: "24px",
    },
    loginButton: {
        fontFamily: "Inter",
        paddingTop: "32px",
        paddingBottom: "24px",
        marginLeft: "24px",
        marginRight: "24px",
    },
    loginBackground: {
        position: "absolute",
        alignSelf: "flex-end",
        top: "50%",
        height: "50%",
        width: "100%",
        backgroundColor: "#001489",
        overflow: "hidden",
    },
    modalInputLabel: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
    },
    settingsWrapper: {
        zIndex: 1,
        paddingTop: "22px",
    },
    optionWrapper: {
        width: "232px",
    },
    collapsedList: {
        width: "70px",
    },
    Avatar: {
        width: "48px",
        height: "48px",
        borderRadius: "50%",
        background: "#dae6f0",
        fontSize: "13px",
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: "48px",
        margin: "15px 0",
        color: "#223654"
    },
    LastMessage: {
        display: "-webkit-box",
        maxWidth: "350px",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    Tabs: {
        minHeight: "66px",
        maxHeight: "66px",
    },
    ActiveTab: {
        fontWeight: "bold",
        fontSize: "14px",
        fontColor: "#223654",
        borderBottom: "4px solid #095797"
    },
    HideScrollBar: {
        overflowY: "auto",
        overflowX: "hidden",
    },
    ChatHeight: {
        maxHeight: "75%",
    },
    ScrollBar: {
        overflowY: "scroll",
        overflowX: "hidden",
        maxHeight: "57%",
    },
};
