export var WHATSAPP_PREFIX = "whatsapp:+";
export var SMS_PREFIX = "+";
export var MAX_FILE_SIZE = 52428800;
export var COPY_SUCCESS_MESSAGE = "Message copied.";
export var UNEXPECTED_ERROR_MESSAGE = "Something went wrong. Please try again.";
export var VALIDATION_ERROR_MESSAGE = "Champs non valid !";
export var CONNECTION_ERROR_MESSAGE = "No internet connection.";
export var NOTIFICATION_TIMEOUT = 4000;
export var ERROR_MODAL_MESSAGES = {
    ADD_PARTICIPANT: {
        title: "Unable to add participant",
        description: "You don’t have permission to add participants to the conversation",
    },
    CHANGE_CONVERSATION_NAME: {
        title: "Unable to save Conversation name",
        description: "Only creators of the Conversation can edit the Conversation name.",
    },
};
export var CONVERSATION_MESSAGES = {
    CREATED: "Conversation created.",
    NAME_CHANGED: "Conversation name changed.",
    LEFT: "You left the conversation.",
};
export var PARTICIPANT_MESSAGES = {
    ADDED: "Participant added.",
    REMOVED: "Participant removed.",
};
export var CONVERSATION_PAGE_SIZE = 30;
export var NOTIFICATION_LEVEL = {
    DEFAULT: "default",
    MUTED: "muted",
};
export var DEFAULT_CONVERSATION_NAME = "Conversation";
