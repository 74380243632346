var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import CreateConversationButton from "./CreateConversationButton";
import ConversationsList from "./ConversationsList";
import ConversationSearch from "./ConversationSearch";
var ConversationsContainer = function (props) {
    var _a = __read(useState(false), 2), listHidden = _a[0], hideList = _a[1];
    var _b = __read(useState(""), 2), searchQuery = _b[0], setSearchQuery = _b[1];
    var handleSearchQuery = function (query) {
        setSearchQuery(query);
    };
    return (_jsxs("div", __assign({ className: "h-100 overflow-hidden px-2 w-100" }, { children: [_jsxs("div", __assign({ className: "d-grid gap-4 mb-4" }, { children: [_jsx(CreateConversationButton, { client: props.client, collapsed: listHidden, isParticipantsComboboxVisible: props.isParticipantsComboboxVisible, setParticipantsCombobox: props.setParticipantsCombobox, isCreatingConversation: props.isCreatingConversation, setIsCreatingConversation: props.setIsCreatingConversation }), _jsx(ConversationSearch, { handleSearchQuery: handleSearchQuery, searchQuery: searchQuery })] })), _jsx("div", __assign({ className: "disable-scrollbars h-100" }, { children: !listHidden ? (_jsx(ConversationsList, { searchQuery: searchQuery })) : null }))] })));
};
export default ConversationsContainer;
