var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Settings from "../settings/Settings";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import { useDispatch } from "react-redux";
var ConversationDetails = function (props) {
    var _a;
    var dispatch = useDispatch();
    var updateCurrentConversation = bindActionCreators(actionCreators, dispatch).updateCurrentConversation;
    return (_jsxs("div", __assign({ className: "d-flex justify-content-between align-items-center  border-bottom convo-header" }, { children: [_jsx("div", __assign({ onClick: function () { return updateCurrentConversation(""); }, className: "me-2 d-lg-none" }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/arrow-left-blue.svg"), alt: "arrow left" }) })), _jsx("h2", __assign({ className: "mb-0 conversation-header-name" }, { children: (_a = props.convo.friendlyName) !== null && _a !== void 0 ? _a : props.convo.sid })), _jsx("div", __assign({ className: "d-flex align-items-center" }, { children: _jsx(Settings, { convo: props.convo, participants: props.participants }) }))] })));
};
export default ConversationDetails;
