var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { conversationsMap } from "../../conversations-objects";
import { ActionType } from "../action-types";
var initialState = [];
var convoSorter = function (a, b) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return ((_e = (_c = (_b = (_a = b.lastMessage) === null || _a === void 0 ? void 0 : _a.dateCreated) === null || _b === void 0 ? void 0 : _b.getTime()) !== null && _c !== void 0 ? _c : (_d = b.dateUpdated) === null || _d === void 0 ? void 0 : _d.getTime()) !== null && _e !== void 0 ? _e : 0) -
        ((_k = (_h = (_g = (_f = a.lastMessage) === null || _f === void 0 ? void 0 : _f.dateCreated) === null || _g === void 0 ? void 0 : _g.getTime()) !== null && _h !== void 0 ? _h : (_j = a.dateUpdated) === null || _j === void 0 ? void 0 : _j.getTime()) !== null && _k !== void 0 ? _k : 0);
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.UPSERT_CONVERSATION:
            var _a = action.payload, sid = _a.sid, friendlyName = _a.friendlyName, dateUpdated = _a.dateUpdated, notificationLevel = _a.notificationLevel, lastReadMessageIndex = _a.lastReadMessageIndex, lastMessage = _a.lastMessage;
            var filteredClone = state.filter(function (conversation) { return conversation.sid !== action.payload.sid; });
            conversationsMap.set(action.payload.sid, action.payload);
            return __spreadArray(__spreadArray([], __read(filteredClone), false), [
                {
                    sid: sid,
                    friendlyName: friendlyName,
                    dateUpdated: dateUpdated,
                    notificationLevel: notificationLevel,
                    lastReadMessageIndex: lastReadMessageIndex,
                    lastMessage: __assign({}, lastMessage),
                    index: state.length,
                },
            ], false).sort(convoSorter);
        case ActionType.UPDATE_CONVERSATION: {
            var stateCopy = __spreadArray([], __read(state), false);
            var target = stateCopy.find(function (convo) { return convo.sid === action.payload.channelSid; });
            if (target) {
                Object.assign(target, __assign({}, action.payload.parameters));
            }
            return stateCopy;
        }
        case ActionType.REMOVE_CONVERSATION: {
            var stateCopy = __spreadArray([], __read(state), false);
            conversationsMap.delete(action.payload);
            return stateCopy.filter(function (convo) { return convo.sid !== action.payload; });
        }
        default:
            return state;
    }
};
export default reducer;
