var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../store";
/**
 * Here we can handle all our errors and success message when we call our APIs
 * @returns
 */
var Notifications = function () {
    var notifications = useSelector(function (state) { return state.notifications; });
    var dispatch = useDispatch();
    var removeNotifications = bindActionCreators(actionCreators, dispatch).removeNotifications;
    useEffect(function () {
        if (!notifications.length) {
            return;
        }
        setTimeout(function () {
            removeNotifications(notifications.length);
        }, 4000);
    }, [notifications]);
    var showNotif = notifications.map(function (notification) { return (_jsx("div", __assign({ className: "" }, { children: _jsxs("div", __assign({ className: "slide-in-from-right popup-note d-flex flex-row justify-content-between ".concat(notification.variant == "success" ? "green" : "red"), style: { zIndex: "999999" } }, { children: [_jsx("div", __assign({ className: "txt" }, { children: notification.message })), _jsx("img", { onClick: function () { return removeNotifications(notifications.length); }, src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/cross-w.svg"), alt: "close", className: "close" })] }), notification.id) }))); });
    return _jsx(_Fragment, { children: showNotif });
};
export default Notifications;
