var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ProductAssetsIcon } from "@twilio-paste/icons/cjs/ProductAssetsIcon";
var MessageFile = function (_a) {
    var media = _a.media, onRemove = _a.onRemove;
    var filename = media.filename, size = media.size;
    var name = filename !== null && filename !== void 0 ? filename : "";
    return (_jsxs("div", __assign({ className: "d-flex align-items-center bg-white  p-3 m-2", style: {
            maxWidth: "200px",
            minWidth: "150px",
            width: "calc(25% - 20px)",
            borderColor: "#CACDD8",
            borderWidth: "1px",
            borderStyle: "solid",
        } }, { children: [_jsx("div", __assign({ className: "me-3 align-items-start" }, { children: _jsx(ProductAssetsIcon, { decorative: false, title: "Open File", size: "sizeIcon60", color: "colorTextLink", style: { fontWeight: "bold" } }) })), _jsxs("div", __assign({ className: "w-100", style: { maxWidth: "calc(100% - 42px)" } }, { children: [_jsx("p", __assign({ className: "font-weight-medium mb-0 text-truncate", title: name }, { children: name })), _jsxs("p", __assign({ className: "text-muted mb-0" }, { children: [Math.round((size / Math.pow(2, 20)) * 100) / 100, " MB"] }))] })), onRemove ? (_jsx("div", __assign({ className: "position-relative", onClick: onRemove, style: { top: "-45px", right: "-20px" } }, { children: _jsx("div", __assign({ className: "delete-file-container" }, { children: _jsx("img", { className: "delete-file-icon", src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/cross-circle-r.svg"), alt: "read" }) })) }))) : null] })));
};
export default MessageFile;
