export var ActionName;
(function (ActionName) {
    ActionName["Save"] = "Save";
    ActionName["Create"] = "Create";
    ActionName["Manage"] = "Manage";
})(ActionName || (ActionName = {}));
export var InputType;
(function (InputType) {
    InputType["Text"] = "text";
    InputType["Password"] = "password";
})(InputType || (InputType = {}));
export var Content;
(function (Content) {
    Content["AddChat"] = "Add chat participant";
    Content["AddSMS"] = "Add SMS participant";
    Content["AddWhatsApp"] = "Add WhatsApp participant";
})(Content || (Content = {}));
