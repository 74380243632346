var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { saveAs } from "file-saver";
import { getBlobFile, getMessageStatus } from "../../api";
import MessageView from "./MessageView";
import { actionCreators } from "../../store";
import ImagePreviewModal from "../modals/ImagePreviewModal";
import { successNotification, unexpectedErrorNotification, } from "../../helpers";
import MessageMedia from "./MessageMedia";
import { getSdkMediaObject, getSdkMessageObject, } from "../../conversations-objects";
import { getSdkConversationObject } from "../../conversations-objects";
import TimeAgo from "javascript-time-ago";
function getMessageTime(message, currentLocal) {
    var dateCreated = message.dateCreated;
    return dateCreated
        ? new TimeAgo(currentLocal).format(dateCreated, "twitter-now")
        : "";
}
var MessageList = function (props) {
    var messages = props.messages, conversation = props.conversation, lastReadIndex = props.lastReadIndex;
    if (messages === undefined) {
        return _jsx("div", { className: "empty" });
    }
    var messagesLength = messages.length;
    var dispatch = useDispatch();
    var _a = bindActionCreators(actionCreators, dispatch), addAttachment = _a.addAttachment, addNotifications = _a.addNotifications;
    var conversationAttachments = useSelector(function (state) { return state.attachments[conversation.sid]; });
    var _b = __read(useState(null), 2), imagePreview = _b[0], setImagePreview = _b[1];
    var _c = __read(useState(0), 2), horizonMessageCount = _c[0], setHorizonMessageCount = _c[1];
    var _d = __read(useState("en-CA"), 2), timeLocal = _d[0], setTimeLocal = _d[1];
    useEffect(function () {
        var _a;
        // @ts-ignore
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        setTimeLocal("".concat(langCode, "-CA"));
    }, []);
    useEffect(function () {
        if (lastReadIndex === -1 || horizonMessageCount) {
            return;
        }
        var showIndex = 0;
        getSdkConversationObject(conversation)
            .getUnreadMessagesCount()
            .then(function (count) {
            setHorizonMessageCount(count !== null && count !== void 0 ? count : 0);
        });
    }, [messages, lastReadIndex]);
    function setTopPadding(index) {
        if (props.messages[index] !== undefined &&
            props.messages[index - 1] !== undefined &&
            props.messages[index].author === props.messages[index - 1].author) {
            return "0.5rem";
        }
        return "2rem";
    }
    var onDownloadAttachments = function (message) { return __awaiter(void 0, void 0, void 0, function () {
        var attachedMedia, attachedMedia_1, attachedMedia_1_1, media, blob, e_1_1;
        var e_1, _a;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    attachedMedia = (_b = message.attachedMedia) === null || _b === void 0 ? void 0 : _b.map(getSdkMediaObject);
                    if (message.index === -1) {
                        return [2 /*return*/, undefined];
                    }
                    if (!(attachedMedia === null || attachedMedia === void 0 ? void 0 : attachedMedia.length)) {
                        return [2 /*return*/, new Error("No media attached")];
                    }
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 6, 7, 8]);
                    attachedMedia_1 = __values(attachedMedia), attachedMedia_1_1 = attachedMedia_1.next();
                    _c.label = 2;
                case 2:
                    if (!!attachedMedia_1_1.done) return [3 /*break*/, 5];
                    media = attachedMedia_1_1.value;
                    return [4 /*yield*/, getBlobFile(media, addNotifications)];
                case 3:
                    blob = _c.sent();
                    addAttachment(props.conversation.sid, message.sid, media.sid, blob);
                    _c.label = 4;
                case 4:
                    attachedMedia_1_1 = attachedMedia_1.next();
                    return [3 /*break*/, 2];
                case 5: return [3 /*break*/, 8];
                case 6:
                    e_1_1 = _c.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 8];
                case 7:
                    try {
                        if (attachedMedia_1_1 && !attachedMedia_1_1.done && (_a = attachedMedia_1.return)) _a.call(attachedMedia_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var onFileOpen = function (file, _a) {
        var filename = _a.filename;
        saveAs(file, filename !== null && filename !== void 0 ? filename : "");
    };
    return (_jsxs(_Fragment, { children: [messages.map(function (message, index) {
                var _a, _b, _c;
                var messageImages = [];
                var messageFiles = [];
                (message.attachedMedia || []).forEach(function (file) {
                    var contentType = file.contentType;
                    if (contentType.includes("image")) {
                        messageImages.push(file);
                        return;
                    }
                    messageFiles.push(file);
                });
                var attributes = message.attributes;
                return (_jsx("div", { children: _jsx(MessageView, { reactions: attributes["reactions"], text: (_a = message.body) !== null && _a !== void 0 ? _a : "", media: ((_b = message.attachedMedia) === null || _b === void 0 ? void 0 : _b.length) ? (_jsx(MessageMedia, { attachments: conversationAttachments === null || conversationAttachments === void 0 ? void 0 : conversationAttachments[message.sid], onDownload: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, onDownloadAttachments(message)];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            }); }); }, images: messageImages, files: messageFiles, sending: message.index === -1, onOpen: function (mediaSid, image, file) {
                                if (file) {
                                    onFileOpen(conversationAttachments === null || conversationAttachments === void 0 ? void 0 : conversationAttachments[message.sid][mediaSid], file);
                                    return;
                                }
                                if (image) {
                                    setImagePreview({
                                        message: message,
                                        file: conversationAttachments === null || conversationAttachments === void 0 ? void 0 : conversationAttachments[message.sid][mediaSid],
                                        sid: mediaSid,
                                    });
                                }
                            } }, message.sid)) : null, author: (_c = message.author) !== null && _c !== void 0 ? _c : "", getStatus: getMessageStatus(message, props.participants), onDeleteMessage: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _b.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, getSdkMessageObject(message).remove()];
                                    case 1:
                                        _b.sent();
                                        successNotification({
                                            message: "Message deleted.",
                                            addNotifications: addNotifications,
                                        });
                                        return [3 /*break*/, 3];
                                    case 2:
                                        _a = _b.sent();
                                        unexpectedErrorNotification(addNotifications);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }, topPadding: setTopPadding(index), lastMessageBottomPadding: index === messagesLength - 1 ? "1rem" : 0, sameAuthorAsPrev: setTopPadding(index) !== "0.5rem", messageTime: getMessageTime(message, timeLocal), updateAttributes: function (attribute) {
                            return getSdkMessageObject(message).updateAttributes(__assign(__assign({}, attributes), attribute));
                        } }) }, message.sid + "message"));
            }), imagePreview
                ? (function () {
                    var _a;
                    var dateString = imagePreview === null || imagePreview === void 0 ? void 0 : imagePreview.message.dateCreated;
                    var date = dateString ? new Date(dateString) : "";
                    return (_jsx(ImagePreviewModal, { image: imagePreview.file, isOpen: !!imagePreview, author: (_a = imagePreview === null || imagePreview === void 0 ? void 0 : imagePreview.message.author) !== null && _a !== void 0 ? _a : "", date: date
                            ? date.toDateString() +
                                ", " +
                                date.getHours() +
                                ":" +
                                (date.getMinutes() < 10 ? "0" : "") +
                                date.getMinutes()
                            : "", handleClose: function () { return setImagePreview(null); }, onDownload: function () {
                            var _a, _b, _c;
                            saveAs(imagePreview.file, (_c = (_b = (_a = imagePreview.message.attachedMedia) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
                                var sid = _a.sid;
                                return sid === imagePreview.sid;
                            })) === null || _b === void 0 ? void 0 : _b.filename) !== null && _c !== void 0 ? _c : "");
                        } }));
                })()
                : null] }));
};
export default MessageList;
