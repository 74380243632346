var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NOTIFICATION_TIMEOUT, UNEXPECTED_ERROR_MESSAGE, VALIDATION_ERROR_MESSAGE, } from "./constants";
export var getTypingMessage = function (typingData) {
    return typingData.length > 1
        ? "".concat(typingData.length + " participants are typing...")
        : "".concat(typingData[0] + " is typing...");
};
export var pushNotification = function (messages, func) {
    if (func) {
        func(messages.map(function (_a) {
            var variant = _a.variant, message = _a.message;
            return ({
                variant: variant,
                message: message,
                id: new Date().getTime(),
                dismissAfter: NOTIFICATION_TIMEOUT,
            });
        }));
    }
};
export var successNotification = function (_a) {
    var message = _a.message, addNotifications = _a.addNotifications;
    if (!addNotifications) {
        return;
    }
    pushNotification([
        {
            message: message,
            variant: "success",
        },
    ], addNotifications);
};
export var unexpectedErrorNotification = function (addNotifications) {
    if (!addNotifications) {
        return;
    }
    pushNotification([
        {
            message: UNEXPECTED_ERROR_MESSAGE,
            variant: "error",
        },
    ], addNotifications);
};
export var validationErrorNotification = function (addNotifications) {
    if (!addNotifications) {
        return;
    }
    pushNotification([
        {
            message: VALIDATION_ERROR_MESSAGE,
            variant: "error",
        },
    ], addNotifications);
};
export var handlePromiseRejection = function (func, addNotifications) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!addNotifications) {
                    return [2 /*return*/];
                }
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                return [4 /*yield*/, func()];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                _a = _b.sent();
                unexpectedErrorNotification(addNotifications);
                return [2 /*return*/, Promise.reject(UNEXPECTED_ERROR_MESSAGE)];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var customControlStyles = {
    boxShadow: "none",
    border: "none",
    borderRadius: 0,
    ":hover": {
        borderRadius: 0,
        boxShadow: "none",
        border: "none",
    },
    ":focus": {
        boxShadow: "none",
        border: "none",
    },
};
export var customOptionStyles = {
    paddingTop: "10px",
    paddingBottom: "10px",
    color: "#223654",
    fontWeight: "600",
    boxShadow: "none",
    ":hover": {
        backgroundColor: "#F1F1F2",
        color: "#223654",
    },
};
export var customValueContainerStyles = {
    height: "62px",
    maxHeight: "62px",
    overflowY: "scroll",
};
export var customMultiValueStylesDesktopStyles = {
    width: "auto",
};
export var customMultiValueStylesMobileStyles = {
    width: "100%",
};
export var customMultiValueLabelDesktopStyles = {
    fontFamily: "Open Sans",
    fontWeight: "500",
    fontSize: "12px",
    color: "#223654",
    backgroundColor: "#F1F1F2",
};
export var customMultiValueLabelMobileStyles = {
    color: "#223654",
    backgroundColor: "#F1F1F2",
    width: "100%",
    fontFamily: "Open Sans",
    fontWeight: "500",
    fontSize: "12px",
    lineSpacing: "20px",
};
export var customMultiValueRemoveStyles = {
    color: "#095797",
    backgroundColor: "#F1F1F2",
    ":hover": {
        backgroundColor: "#F1F1F2",
        color: "#095797",
    },
};
export var customMenuDesktopStyles = {
    top: "108%",
    boxShadow: "0 1px 4px rgb(34 54 84 / 24%)",
    borderTop: "none",
    borderRadius: "0px",
    marginTop: "3px",
};
export var customMenuMobileStyles = {
    boxShadow: "none",
    marginTop: "0px",
};
export var customMenuListMobileStyles = {
    fontSize: "12px",
    paddingTop: "0px",
};
export var customMenuListDesktopStyles = {
    fontSize: "12px",
    maxHeight: "100%",
};
export var customIndicatorSeparatorStyles = {
    display: "none",
};
