var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useCallback, useRef, useEffect, } from "react";
import debounce from "lodash/debounce";
import axios from "axios";
import { Box } from "@twilio-paste/core";
import AsyncSelect from "react-select/async";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { customControlStyles, customOptionStyles, customValueContainerStyles, customMultiValueStylesDesktopStyles, customMultiValueStylesMobileStyles, customMultiValueLabelDesktopStyles, customMultiValueLabelMobileStyles, customMultiValueRemoveStyles, customMenuDesktopStyles, customMenuMobileStyles, customMenuListMobileStyles, customMenuListDesktopStyles, customIndicatorSeparatorStyles, } from "../../helpers";
// @ts-ignore
import LetteredAvatar from "react-lettered-avatar";
import { useTranslation } from "react-i18next";
var ConversationAddParticipants = function (props) {
    var isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
    var _a = __read(useState(true), 2), menuOpen = _a[0], setMenuOpen = _a[1];
    var selectRef = useRef(null);
    var t = useTranslation(["chat"]).t;
    var handleMenuClose = function (event) {
        if (selectRef.current && selectRef.current.contains(event.target)) {
            setMenuOpen(true);
        }
        else {
            setMenuOpen(false);
        }
    };
    var getAsyncOptions = function (inputText) {
        return axios
            .get("/api/twillio/users/?search=".concat(inputText))
            .then(function (response) {
            return response.data.results.map(function (user) { return ({
                value: user.email,
                label: "".concat(user.first_name, " ").concat(user.last_name),
            }); });
        })
            .catch(function (error) {
            console.log(error);
        });
    };
    var loadOptions = useCallback(debounce(function (inputText, callback) {
        getAsyncOptions(inputText).then(function (options) { return callback(options); });
    }, 1000), []);
    var formatOptionLabel = function (data) {
        return (_jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [_jsx("div", __assign({ className: "pe-2" }, { children: _jsx(LetteredAvatar, { backgroundColor: "#dae6f0", size: 30, name: "".concat(data.label.toUpperCase()) }) })), _jsx("div", __assign({ className: "d-flex flex-column" }, { children: _jsx("span", { children: data.value }) }))] })));
    };
    var onChange = function (e) {
        props === null || props === void 0 ? void 0 : props.setSelectedUsers(e);
    };
    var focusInput = function () {
        if (selectRef.current) {
            selectRef.current.focus();
        }
    };
    useEffect(function () {
        focusInput();
        setMenuOpen(true);
    }, []);
    var onBlur = function (event) {
        var _a, _b, _c;
        if (event.relatedTarget === null ||
            !((_c = (_b = (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.select) === null || _b === void 0 ? void 0 : _b.controlRef) === null || _c === void 0 ? void 0 : _c.contains(event.relatedTarget))) {
            setMenuOpen(false);
        }
    };
    var customStyles = {
        control: function (provided, state) {
            return __assign(__assign({}, provided), customControlStyles);
        },
        option: function (provided, state) {
            return __assign(__assign({}, provided), customOptionStyles);
        },
        valueContainer: function (provided, state) {
            return __assign(__assign({}, provided), customValueContainerStyles);
        },
        multiValue: function (provided, state) {
            var customStyle = isTabletOrMobile
                ? customMultiValueStylesMobileStyles
                : customMultiValueStylesDesktopStyles;
            return __assign(__assign({}, provided), customStyle);
        },
        multiValueLabel: function (provided, state) {
            var customStyle = isTabletOrMobile
                ? customMultiValueLabelMobileStyles
                : customMultiValueLabelDesktopStyles;
            return __assign(__assign({}, provided), customStyle);
        },
        multiValueRemove: function (provided, state) {
            return __assign(__assign({}, provided), customMultiValueRemoveStyles);
        },
        menu: function (provided, state) {
            var customStyle = isTabletOrMobile
                ? customMenuMobileStyles
                : customMenuDesktopStyles;
            return __assign(__assign({}, provided), customStyle);
        },
        menuList: function (provided, state) {
            var customStyle = isTabletOrMobile
                ? customMenuListMobileStyles
                : customMenuListDesktopStyles;
            return __assign(__assign({}, provided), customStyle);
        },
        indicatorSeparator: function (provided, state) {
            return __assign(__assign({}, provided), customIndicatorSeparatorStyles);
        },
    };
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "participants-select-container" }, { children: _jsxs("div", __assign({ className: "participants-tags" }, { children: [_jsx("span", __assign({ className: isTabletOrMobile ? "d-none" : "" }, { children: t("To") })), _jsx(Box, __assign({ style: { flex: "2 1 auto" } }, { children: _jsx(AsyncSelect, { placeholder: t("Select"), ref: selectRef, classNamePrefix: "react-select-pn", onChange: onChange, isMulti: true, isSearchable: true, cacheOptions: true, defaultOptions: true, loadOptions: loadOptions, autoFocus: true, menuIsOpen: menuOpen, 
                            // onMenuClose={() => setMenuOpen(false)}
                            onMenuOpen: function () { return setMenuOpen(true); }, onBlur: onBlur, formatOptionLabel: formatOptionLabel, isClearable: false, maxMenuHeight: 250, styles: customStyles, classNames: {
                                valueContainer: function () { return classNames("disable-scrollbars"); },
                                option: function (_a) {
                                    var isDisabled = _a.isDisabled, isFocused = _a.isFocused, isSelected = _a.isSelected;
                                    return classNames(isSelected && "bg-purple-800", !isSelected && isFocused && "bg-purple-300", !isDisabled && isSelected && "active:bg-purple-800", !isDisabled && !isSelected && "active:bg-purple-500");
                                },
                            } }) }))] })) })) }));
};
export default ConversationAddParticipants;
