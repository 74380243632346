var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { addConversation, addParticipant } from "../../api";
import { actionCreators } from "../../store";
import { MAX_FILE_SIZE, UNEXPECTED_ERROR_MESSAGE } from "../../constants";
import { unexpectedErrorNotification } from "../../helpers";
import MessageInput from "./MessageInput";
import SendMessageButton from "./SendMessageButton";
import { getSdkConversationObject } from "../../conversations-objects";
import EmojiPicker from "./EmojiPicker";
import { ERROR_MODAL_MESSAGES } from "../../constants";
var MessageInputFieldCreateConvo = function (props) {
    var _a;
    var _b = __read(useState(), 2), showError = _b[0], setErrorToShow = _b[1];
    var _c = __read(useState(), 2), errorData = _c[0], setErrorData = _c[1];
    var _d = __read(useState(""), 2), message = _d[0], setMessage = _d[1];
    var _e = __read(useState([]), 2), files = _e[0], setFiles = _e[1];
    var _f = __read(useState(false), 2), showEmojis = _f[0], setShowEmojis = _f[1];
    // needed to clear input type=file
    var _g = __read(useState("input-key"), 2), filesInputKey = _g[0], setFilesInputKey = _g[1];
    var dispatch = useDispatch();
    var _h = bindActionCreators(actionCreators, dispatch), updateCurrentConversation = _h.updateCurrentConversation, updateParticipants = _h.updateParticipants, addNotifications = _h.addNotifications;
    // @ts-ignore
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    useEffect(function () {
        setMessage("");
        setFiles([]);
        setFilesInputKey(Date.now().toString());
    }, []);
    useEffect(function () {
        if (!files.length) {
            setFilesInputKey(Date.now().toString());
        }
    }, [files]);
    var onFilesChange = function (event) {
        var assets = event.target.files;
        if (!(assets === null || assets === void 0 ? void 0 : assets.length)) {
            return;
        }
        var validFiles = Array.from(assets).filter(function (_a) {
            var size = _a.size;
            return size < MAX_FILE_SIZE + 1;
        });
        if (validFiles.length < assets.length) {
            // TODO: show error
        }
        setFiles(__spreadArray(__spreadArray([], __read(files), false), __read(validFiles), false));
    };
    var onFileRemove = function (file) {
        var fileIdentityArray = file.split("_");
        var fileIdentity = fileIdentityArray
            .slice(0, fileIdentityArray.length - 1)
            .join();
        var existentFiles = files.filter(function (_a) {
            var name = _a.name, size = _a.size;
            return name !== fileIdentity &&
                size !== Number(fileIdentityArray[fileIdentityArray.length - 1]);
        });
        setFiles(existentFiles);
    };
    var addParticipantToConvo = function (participant, convo) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, addParticipant(participant, "", true, convo, addNotifications)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
                    setErrorData(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    /**
    Create conversation
        - Add participants
        - Send Message
        - Set conversation as current active conversation
  */
    var onMessageSend = function () { return __awaiter(void 0, void 0, void 0, function () {
        var client, convo, sdkConvo, participants, newMessageBuilder, _a, _b, _c, key, file, fileData, messageIndex, e_2;
        var e_3, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (message.length == 0 && files.length == 0) {
                        return [2 /*return*/];
                    }
                    client = props.client;
                    return [4 /*yield*/, addConversation("Conversation", updateParticipants, client, addNotifications)];
                case 1:
                    convo = _e.sent();
                    sdkConvo = getSdkConversationObject(convo);
                    participants = __spreadArray([], __read(props.selectedUsers), false);
                    participants.map(function (participant) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, addParticipantToConvo(participant.value, sdkConvo)];
                                case 1:
                                    participant = _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                    updateCurrentConversation(convo.sid);
                    newMessageBuilder = sdkConvo.prepareMessage().setBody(message);
                    try {
                        for (_a = __values(files.entries()), _b = _a.next(); !_b.done; _b = _a.next()) {
                            _c = __read(_b.value, 2), key = _c[0], file = _c[1];
                            fileData = new FormData();
                            fileData.set(file.name, file, file.name);
                            newMessageBuilder.addMedia(fileData);
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_b && !_b.done && (_d = _a.return)) _d.call(_a);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                    setMessage("");
                    setFiles([]);
                    return [4 /*yield*/, newMessageBuilder.build().send()];
                case 2:
                    messageIndex = _e.sent();
                    _e.label = 3;
                case 3:
                    _e.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, sdkConvo.updateLastReadMessageIndex(messageIndex)];
                case 4:
                    _e.sent();
                    return [3 /*break*/, 6];
                case 5:
                    e_2 = _e.sent();
                    unexpectedErrorNotification(addNotifications);
                    return [2 /*return*/, Promise.reject(UNEXPECTED_ERROR_MESSAGE)];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleAddEmoji = function (e) {
        var emoji = e.native;
        setMessage(message + emoji);
    };
    return (_jsx("div", __assign({ className: "d-flex flex-column border-top w-100 ps-4", style: { flexBasis: "60px", flexGrow: 10 } }, { children: _jsxs("div", __assign({ className: "d-flex flex-column h-100 pb-2 " }, { children: [_jsxs("div", __assign({ className: "chat-input-text pt-4 d-flex flex-row" }, { children: [_jsx("div", __assign({ className: "flex-grow-1 pr-2" }, { children: _jsx(MessageInput, { assets: files, message: message, onChange: function (e) {
                                    setMessage(e);
                                }, onKeyPress: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!(e.key === "Enter" && e.shiftKey == false)) return [3 /*break*/, 2];
                                                return [4 /*yield*/, onMessageSend()];
                                            case 1:
                                                _a.sent();
                                                _a.label = 2;
                                            case 2: return [2 /*return*/];
                                        }
                                    });
                                }); }, onFileRemove: onFileRemove }) })), _jsx("div", __assign({ className: "d-flex flex-column justify-content-start align-items-start" }, { children: message || files.length ? (_jsx(SendMessageButton, { message: message, onClick: onMessageSend })) : null }))] })), _jsxs("div", __assign({ className: "chat-actions d-flex flex-row pt-3" }, { children: [_jsxs("div", __assign({ className: "chat-emoji-picker pe-2" }, { children: [_jsx("img", { className: "emoji-picker", src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/smile.svg"), alt: "smile picker", onClick: function () { return setShowEmojis(!showEmojis); } }), _jsx(EmojiPicker, { showEmojis: showEmojis, setShowEmojis: setShowEmojis, handleAddEmoji: handleAddEmoji, locale: langCode })] })), _jsxs("div", __assign({ className: "file-picker " }, { children: [_jsx("label", __assign({ htmlFor: "file-input" }, { children: _jsx("img", { className: "attach-file", src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/attach.svg"), alt: "attach file" }) })), _jsx("input", { id: "file-input", type: "file", style: { display: "none" }, onChange: onFilesChange }, filesInputKey)] }))] }))] })) })));
};
export default MessageInputFieldCreateConvo;
