var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@twilio-paste/core";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
var EmojiPicker = function (_a) {
    var showEmojis = _a.showEmojis, setShowEmojis = _a.setShowEmojis, handleAddEmoji = _a.handleAddEmoji, _b = _a.locale, locale = _b === void 0 ? "en" : _b;
    var i18nData = require("@emoji-mart/data/i18n/".concat(locale, ".json"));
    return (_jsx(_Fragment, { children: showEmojis && (_jsx(Box, __assign({ style: { position: "absolute", bottom: "95px" } }, { children: _jsx(Picker, { theme: "light", data: data, navPosition: "none", onClickOutside: function () { return setShowEmojis(!showEmojis); }, onEmojiSelect: handleAddEmoji, previewPosition: "none", autoFocus: true, i18n: i18nData }) }))) }));
};
export default EmojiPicker;
