import { ActionType } from "../action-types";
var initialState = "";
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.UPDATE_CURRENT_CONVERSATION:
            return action.payload;
        default:
            return state;
    }
};
export default reducer;
