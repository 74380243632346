var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ModalBody, Box } from "@twilio-paste/core";
import ModalInputField from "./ModalInputField";
import AddParticipantFooter from "./addParticipantFooter";
import { ActionName } from "../../types";
import ConvoModal from "./ConvoModal";
var AddSMSParticipantModal = function (props) {
    return (_jsx(_Fragment, { children: _jsx(ConvoModal, { handleClose: function () { return props.handleClose(); }, isModalOpen: props.isModalOpen, title: props.title, modalBody: _jsxs(ModalBody, { children: [_jsx("h3", { children: "Add SMS participant" }), _jsxs(Box, __assign({ as: "form" }, { children: [_jsx(ModalInputField, { isFocused: true, label: "Phone number", input: props.name, placeholder: "123456789012", onChange: props.setName, error: props.error, 
                                // error_text="Enter a valid phone number."
                                help_text: "The phone number of the participant.", prefixType: "SMS" }), _jsx(ModalInputField, { label: "Proxy phone number", input: props.proxyName, placeholder: "123456789012", onChange: props.setProxyName, error: props.errorProxy, 
                                // error_text="Enter a valid Twilio phone number."
                                help_text: "The Twilio phone number used by the participant in Conversations.", prefixType: "SMS" })] }))] }), modalFooter: _jsx(AddParticipantFooter, { isSaveDisabled: !props.name || !props.proxyName || !!props.error, actionName: ActionName.Save, onBack: function () {
                    props.onBack();
                }, action: props.action }) }) }));
};
export default AddSMSParticipantModal;
