var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { MessageStatus, } from "../../store/reducers/messageListReducer";
import SendingIcon from "../icons/Sending";
import DeliveredIcon from "../icons/Delivered";
import FailedIcon from "../icons/Failed";
import BellMuted from "../icons/BellMuted";
import { NOTIFICATION_LEVEL } from "../../constants";
import { getMessageStatus } from "../../api";
import * as _ from "lodash";
import TimeAgo from "javascript-time-ago";
import ConversationAvatar from "./ConversationAvatar";
import ConversationTitle from "./ConversationTitle";
var measureWidth = function (text) {
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    if (!context) {
        return 0;
    }
    context.font = "bold 14px Inter";
    return context.measureText(text).width;
};
function calculateUnreadMessagesWidth(count) {
    if (count === 0 || !count) {
        return 0;
    }
    return measureWidth(count.toString()) + 32;
}
function truncateMiddle(text, countWidth) {
    var width = measureWidth(text);
    if (width > 288 - countWidth) {
        var textLength = text.length;
        var avgLetterSize = width / textLength;
        var nrOfLetters = (288 - countWidth) / avgLetterSize;
        var delEachSide = (textLength - nrOfLetters + 1) / 2;
        var endLeft = Math.floor(textLength / 2 - delEachSide);
        var startRight = Math.ceil(textLength / 2 + delEachSide);
        return text.substr(0, endLeft) + "..." + text.substr(startRight);
    }
    return text;
}
function getLastMessageTime(messages, currentLocale) {
    var _a;
    var lastMessageDate = (_a = _.last(messages)) === null || _a === void 0 ? void 0 : _a.dateCreated;
    if (!lastMessageDate) {
        return "";
    }
    return new TimeAgo(currentLocale).format(lastMessageDate, "twitter-now");
}
var ConversationView = function (props) {
    var _a;
    var convo = props.convo, convoId = props.convoId, myMessage = props.myMessage, lastMessage = props.lastMessage, unreadMessagesCount = props.unreadMessagesCount;
    var _b = __read(useState(), 2), backgroundColor = _b[0], setBackgroundColor = _b[1];
    var title = truncateMiddle((_a = convo.friendlyName) !== null && _a !== void 0 ? _a : convo.sid, calculateUnreadMessagesWidth(unreadMessagesCount));
    var _c = __read(useState("en-CA"), 2), timeLocal = _c[0], setTimeLocal = _c[1];
    var muted = convo.notificationLevel === NOTIFICATION_LEVEL.MUTED;
    var _d = __read(useState(""), 2), lastMsgStatus = _d[0], setLastMsgStatus = _d[1];
    var time = getLastMessageTime(props.messages, timeLocal);
    useEffect(function () {
        var _a;
        // @ts-ignore
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        setTimeLocal("".concat(langCode, "-CA"));
    }, []);
    useEffect(function () {
        if (props.currentConvoSid === convo.sid) {
            setBackgroundColor("bg-strong");
            return;
        }
        setBackgroundColor("bg-striped");
    }, [props.currentConvoSid, convo.sid]);
    useEffect(function () {
        if (myMessage && !props.typingInfo.length) {
            getMessageStatus(myMessage, props.participants).then(function (statuses) {
                if (statuses[MessageStatus.Read]) {
                    setLastMsgStatus(MessageStatus.Read);
                    return;
                }
                if (statuses[MessageStatus.Delivered]) {
                    setLastMsgStatus(MessageStatus.Delivered);
                    return;
                }
                if (statuses[MessageStatus.Failed]) {
                    setLastMsgStatus(MessageStatus.Failed);
                    return;
                }
                if (statuses[MessageStatus.Sending]) {
                    setLastMsgStatus(MessageStatus.Sending);
                    return;
                }
            });
        }
    }, [convo, myMessage, lastMessage, props.participants, props.typingInfo]);
    return (_jsx("a", __assign({ href: "#" }, { children: _jsx("div", __assign({ className: "card-body conversation-height-95 pt-2 pb-2 pe-2 ps-2 border-bottom ".concat(backgroundColor, " ").concat(convo.sid === props.currentConvoSid && "current-convo", " ").concat(unreadMessagesCount > 0 && "convo-unread", " ").concat(muted ? " text-muted" : ""), id: convoId, onMouseOver: function () {
                if (convo.sid === props.currentConvoSid) {
                    return;
                }
                setBackgroundColor("current-convo");
            }, onMouseOut: function () {
                if (convo.sid === props.currentConvoSid) {
                    return;
                }
                setBackgroundColor("");
            }, onClick: props.onClick }, { children: _jsxs("div", __assign({ className: "row gx-3" }, { children: [_jsx("div", __assign({ className: "col-auto" }, { children: _jsx(ConversationAvatar, { participants: props.participants }) })), _jsxs("div", __assign({ className: "col align-self-center" }, { children: [_jsxs("div", __assign({ className: "d-flex align-items-center mb-3" }, { children: [_jsx("h5", __assign({ className: "me-auto mb-0 ".concat(muted ? "text-muted" : "", " convo-name") }, { children: _jsx(ConversationTitle, { title: title, participants: props.participants }) })), _jsx("span", __assign({ className: "extra-small ms-2 convo-time" }, { children: time }))] })), _jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [_jsxs("div", __assign({ className: "line-clamp me-auto text-truncate convo-last-message" }, { children: [!props.typingInfo.length ? (_jsxs(_Fragment, { children: [lastMsgStatus === MessageStatus.Sending &&
                                                        props.myMessage && _jsx(SendingIcon, {}), lastMsgStatus === MessageStatus.Delivered &&
                                                        props.myMessage && _jsx(DeliveredIcon, {}), lastMsgStatus === MessageStatus.Failed &&
                                                        props.myMessage && _jsx(FailedIcon, {}), lastMsgStatus === MessageStatus.Read &&
                                                        props.myMessage && (_jsx("img", { className: "message-read-icon me-2", src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/read-message.svg"), alt: "read" }))] })) : null, lastMessage] })), _jsx("span", { children: muted && _jsx(BellMuted, {}) }), _jsx("div", __assign({ className: "badge badge-circle bg-primary ms-1 convo-unread-count" }, { children: unreadMessagesCount > 0 && _jsx("span", { children: unreadMessagesCount }) }))] }))] }))] })) })) })));
};
export default ConversationView;
